import {ORGANIZATION_HOST, ORGANIZATION_INSTANCE,ACCOUNT_HOST, ACCOUNTS_INSTANCE, PAY_HOST, PAY_INSTANCE,} from "@/helper/consts";
import {checkUrlAllowedWithParameters, getUrlAllowed, getUrlWithParameters, makeMediaHostUrl} from "@/helper/urls";


export default {
    getAccountsUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets
        );
    },
    getPayUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            PAY_HOST, PAY_INSTANCE, name, method, parameters, gets
        );
    },
    getWorkerUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            ORGANIZATION_HOST, ORGANIZATION_INSTANCE, name, method, parameters, gets
        );
    },
    allowedPayUrl(name, method) {
        return getUrlAllowed(PAY_HOST, name, method)
    },
    allowedPayUrlByParameters(name, method, parameters) {
        return checkUrlAllowedWithParameters(PAY_HOST, PAY_INSTANCE, name, method, parameters)
    },
    makeMessagingMediaUrl: function (append) {
        return makeMediaHostUrl(PAY_HOST, append)
    },
}
