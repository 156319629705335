import store from "@/store";


export function getInstMenu() {
    /**
     * Функция которая возвращает нижнее боковое меню инстанса
     * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
     */
    let menu = []

    if (store.getters['urls/getAllowed']( 'pay', 'admin|pay|models|transaction-list', 'list'))
        menu.push({title: 'Администрирование', icon: 'settings_applications', router: "Pay|Admin", name: 'Admin',})
    menu.push({title: 'Оплата услуг', icon: 'shopping_bag', router: "Pay", name: 'Pay',})
    return menu
}

export function getInstNameByRouterName(router) {
    /**
     * Функция которая возвращает нижнее боковое меню инстанса
     * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
     */
    return getInstMenu().find(el => {
        return router === el.router
    }).name
}
