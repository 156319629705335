import {ACCOUNTS_INSTANCE, PAY_INSTANCE} from "@/helper/consts";
import Base from "@/modules/pay/page/Base"
import names from '@/modules/pay/routers/names'
import adminPay from '@/modules/pay/routers/adminPay'
import core from '@/modules/core/routers'
import publicPay from '@/modules/pay/routers/publicPay'
import userPay from '@/modules/pay/routers/userPay'

export default ([
    {
        path: '',
        name: names.BASE,
        component: Base,
        meta: {
            breadcrumb: 'Оплата услуг',
            requiredAuth: false,
            instances: [
                PAY_INSTANCE,
                ACCOUNTS_INSTANCE
            ]
        },
        children: [
            ...publicPay,
        ]
    },

    ...adminPay,

    ...userPay,
    ...core,

])


