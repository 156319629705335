<template>
    <v-app-bar :clipped-left="clipped" app class="letter_spacing_disabled" color="primary" dark fixed height="60">
        <v-app-bar-nav-icon @click.stop="leftMenuToggle" class="black--text" v-if="leftmenu.enabled">
            <v-icon color="white">menu</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="hidden-sm-and-down">
            <v-avatar class="" size="35">
                <v-img :src="logo" alt="logo" aspect-ratio="1" contain></v-img>
            </v-avatar>
            <span class="white--text letter_spacing_disabled" v-if="maintoolbar.title">
        {{ maintoolbar.title }}
      </span>
            <span class="white--text letter_spacing_disabled" v-else>
          <router-link :to="{ name: 'Pay'}" class="white--text " style="text-decoration: none;"> РХТУ им. Д.И. Менделеева</router-link>
      </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <!--УПАРАВЛЕНИЕ АККАУНТОМ | ОБНОВЛЕНИЯ : НАЧАЛО -->
        <v-toolbar-items>
<!--            <v-toolbar-items class="hidden-sm-and-down">-->
<!--                <v-btn class="white&#45;&#45;text letter_spacing_disabled" href="https://muctr.ru/portal-instruction/" mr-5-->
<!--                       target="_blank" text>-->
<!--                    Инструкция-->
<!--                </v-btn>-->
<!--            </v-toolbar-items>-->
            <!--      <v-toolbar-items v-for="(item, i) in maintoolbar.mainInstances" :key="i" class="hidden-sm-and-down">-->
            <!--        <v-btn class="white&#45;&#45;text letter_spacing_disabled" mr-5 text @click="dispatchRouter(item)">-->
            <!--        <span v-if="item.router">-->
            <!--          {{item.title}}-->
            <!--        </span>-->
            <!--          <span v-else>-->
            <!--          {{item.title}}-->
            <!--        </span>-->
            <!--        </v-btn>-->
            <!--      </v-toolbar-items>-->

            <v-menu bot flat offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn class="white--text letter_spacing_disabled" mr-5 text v-on="on">
                        Сервисы
                        <v-icon>arrow_drop_down</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                            :key=item.title
                            @click="routerPath(item)"
                            v-for="item in maintoolbar.secondInstances">

                        <v-list-item-title v-text="item.title"></v-list-item-title>

                    </v-list-item>
                </v-list>

            </v-menu>

            <!--КНОПКА АВТОРИЗАЦИИ : НАЧАЛО-->
            <v-menu bot offset-y v-if="isUserAuthenticated">
                <template v-slot:activator="{ on }">
                    <v-btn class="white--text letter_spacing_disabled" text v-on="on">
                        <v-avatar size="30">
                            <img :src="NotAvatar" alt="avatar"
                                 v-if="maintoolbar.userAvatar== null ">
                            <v-img :src="maintoolbar.userAvatar"
                                   v-else></v-img>
                        </v-avatar>
                      <span v-if="isUserAuthenticated && $vuetify.breakpoint.width > 500" class="pa-1" >
              {{ user.username }}
            </span>
                        <v-icon>arrow_drop_down</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item :key="i" v-for="(item, i) in maintoolbar.userActions">
                        <v-list-item-title>
                            <router-link :to="{ name: item.route}">
                                {{ item.title }}
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title @click="logout(false, true, false)" style="text-decoration: underline; cursor:pointer">
                            Выйти
                        </v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-menu>
            <!--КНОПКА АВТОРИЗАЦИИ : КОНЕЦ-->

            <v-toolbar-items v-else>

                <v-btn @click="routerLogin" class="white--text letter_spacing_disabled" mr-5 text>
                    Войти
                </v-btn>
            </v-toolbar-items>

        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
    import logo from "@/assets/logo.png";
    import NotAvatar from "@/assets/NotAvatar.png";

    export default {
        name: "topmenu",
        data() {
            return {
                clipped: true,
                drawer: true,
                logo: logo,
                NotAvatar: NotAvatar,
            }
        },
        computed: {
          ...mapGetters({
            isUserAuthenticated: 'user/isAuthenticate'
          }),
            ...mapState({
                leftmenu: state => state.leftmenu,
                maintoolbar: state => state.maintoolbar,
                user: state => state.user,
            }),
        },
        methods: {
            routerLogin() {
                document.location.href = `${process.env.VUE_APP_LOGIN_HOST}?from=${document.location.href}`;
            },
            routerPath(item) {
                if (item.router)
                    this.$router.push({path: item.router});
                else if (item.url) {
                    window.open(item.url, '_blank');
                } else {
                    this.$router.push({name: item.name});
                }
            },
            dispatchRouter(item) {
                if (item.router)
                    this.$router.push({name: item.router});
            },
            leftMenuToggle() {
                this.$store.commit('leftmenu/toggleVisible');
            },

          logout: function (dissable_left=true, reload_page=true, redirect=false) {
            this.$store.commit('urls/clearUrls');
            if (dissable_left)
              this.$store.commit('leftmenu/dissable');
            this.$store.commit('leftmenu/replaceItems', []);
            this.$store.dispatch('user/AUTH_LOGOUT')
                .then(() => {
                  if (redirect)
                    document.location.href = process.env.VUE_APP_LOGIN_HOST
                  if (reload_page)
                    document.location.reload()
                });
          },
        },
    }
</script>

<style scoped>
    .letter_spacing_disabled {
        letter-spacing: 0 !important;
    }
</style>
