import helper from "./helper";
// import user from "../store/modules/user";

let getPayUrlWithParameters = helper.getPayUrlWithParameters;

export default {
    getPayUrlWithParameters,
    getByRecievedUrlData: (url, gets = null) => getPayUrlWithParameters(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    USER: {
        TRANSACTION: {
            LIST:
                (params= null, gets = null) => getPayUrlWithParameters(
                    "public|pay|models|transaction-list",
                    "list",
                    params,
                    gets
                ),
            DETAIL:
                (params= null, gets = null) => getPayUrlWithParameters(
                    "public|pay|models|transaction-detail",
                    "destroy",
                    params,
                    gets
                ),
        },
    },
     PUBLIC: {
         CATEGORY: {
             LIST:
                 (params= null, gets = null) => getPayUrlWithParameters(
                     "public|pay|models|category-list",
                     "list",
                     params,
                     gets
                 ),
         },
         FORM: {
             LIST:
                 (params= null, gets = null) => getPayUrlWithParameters(
                     "public|pay|models|form-list",
                     "list",
                     params,
                     gets
                 ),
             DETAIL:
                 (params = null, gets = null) => getPayUrlWithParameters(
                     'public|pay|models|form-detail',
                     'retrieve',
                     params,
                     gets
                 ),
         },
         TRANSACTION: {
             START :
                 (params= null, gets = null)=>getPayUrlWithParameters(
                     'public|pay|actions|start_transaction',
                     'post',
                     params,
                     gets
                 )
         }
     }
}
