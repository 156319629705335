<template>
    <v-container style="height: 100%">
        <div class="pb-4">
            <v-expansion-panels>
                <v-expansion-panel
                >
                    <v-expansion-panel-header>
                        <div>
                            <v-icon>search</v-icon>
                            Поиск
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-0">
                        <v-text-field
                                dense
                                outlined
                                placeholder="Поиск"
                                v-model="search"
                        >
                        </v-text-field>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn @click="search=''">Сбросить фильтр</v-btn>
                        </v-card-actions>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <v-card flat>
            <v-card-title class="title style-color-main-gray">
                <span>Список полей</span>
                <v-spacer></v-spacer>
                <v-btn @click="opened_form = true" color="secondary" text>
                    <v-icon>add</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                @click="getItems()"
                                class="mr-2"
                                medium
                                v-on="on"
                        >
                            update
                        </v-icon>
                    </template>
                    <span>Обновить данные</span>
                </v-tooltip>
            </v-card-title>
            <v-data-table
                    :footer-props="{'items-per-page-options':[100,250,500], 'items-per-page-text': 'Число элементов'}"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :search="search"
                    class="elevation-1"
                    no-data-text="Нет данных"
            >
                <template v-slot:item.id="{ item }">
                    #{{item.id}}#
                </template>
                <template v-slot:item.readOnly="{ item }">
                    <v-icon v-if=" item.readOnly">done</v-icon>
                    <v-icon v-else>clear</v-icon>
                </template>
                <template v-slot:item.required="{ item }">
                    <v-icon v-if=" item.required">done</v-icon>
                    <v-icon v-else>clear</v-icon>
                </template>
                <template v-slot:item.hidden="{ item }">
                    <v-icon v-if=" item.hidden">done</v-icon>
                    <v-icon v-else>clear</v-icon>
                </template>
                <template v-slot:item.metaType="{ item }">
                    {{ getDisplay(item.metaType, selectors.metaType) }}
                </template>
                <template v-slot:item.type="{ item }">
                    {{ getDisplay(item.type, selectors.type) }}
                </template>
                <template v-slot:item.btn="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="updateItem(item)"
                                    class="mr-2"
                                    medium
                                    v-on="on"
                            >
                                edit
                            </v-icon>
                        </template>
                        <span>Редактировать</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="openConfirmDelete(item)"
                                    medium
                                    v-on="on"
                            >
                                delete
                            </v-icon>
                        </template>
                        <span>Удалить</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <v-card-text>Данные не загружены</v-card-text>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog @input="closedForm" persistent v-model="opened_form" width="500">
            <Form @addItem="addItem"
                  @ended="closedForm"
                  @refreshItem="refreshItem"
                  v-bind="selected"
                  v-bind:info="$route.params.id"
                  v-if="opened_form"
            >
            </Form>
        </v-dialog>

        <v-dialog persistent v-model="opened_confirm" width="500">
            <v-card v-if="deletable">
                <v-card-title>Подтвердите удаление поля</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Вы уверены что хотите удалить {{deletable.title}} ?<br>
                </v-card-text>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="deleteItem(deletable.id)"
                                color="warning"
                                text
                        >
                            Удалить
                        </v-btn>
                        <v-btn
                                @click="closeConfirmDelete"
                                text
                        >Отмена
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="errorDialog" width="500">
            <v-card>
                <v-card-title>Удаление закончилось ошибкой</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-for="field in errorDelete">
                    {{String(field)}}
                </v-card-text>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="errorDialog=false"
                                text
                        >Закрыть
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
    import UserDataMixin from '@/mixins/UserDataMixin'
    import {makeDeleteRequest, makeGetRequest} from "@/helper/requests";
    import urls from '@/urls/helper.js'
    import Form from "./Form"
    import {clearUserAuth} from "@/helper";

    export default {
        name: "List",
        mixins: [UserDataMixin],
        components: {Form},
        data() {
            return {
                selectors: {
                    type: [],
                    metaType: [],
                },
                search: "",
                errorDelete: [],
                errorDialog: false,
                URL_LIST: urls.getPayUrlWithParameters('admin|pay|models|field-list', 'list'),
                URL_DELETE: urls.getPayUrlWithParameters('admin|pay|models|field-detail', 'destroy'),
                items: [],
                loading: true,
                headers: [
                    {text: 'Заголовок', sortable: false, align: 'left', value: 'title'},
                    // {text: 'Подсказка', sortable: false, align: 'left',value:'helpText'},
                    // {text: 'Регулярное выражение', sortable: false, align: 'left',value:'regex'},
                    {text: 'Тип', sortable: false, align: 'left', value: 'type'},
                    {text: 'Метатип', sortable: false, align: 'left', value: 'metaType'},
                    // {text: 'Значение по умолчанию', sortable: false, align: 'left',value:'defaultValue'},
                    {text: 'Только чтение', sortable: false, align: 'left', value: 'readOnly'},
                    // {text: 'Заполнитель', sortable: false, align: 'left',value:'placeholder'},
                    {text: 'Обязательное', sortable: false, align: 'left', value: 'required'},
                    {text: 'Скрытое', sortable: false, align: 'left', value: 'hidden'},
                    // {text: 'id Поля', sortable: false, align: 'left',value:'id'},
                    {text: 'Действия', sortable: false, align: 'right', value: 'btn'},
                ]
            }
        },

        created() {
            let url = urls.getPayUrlWithParameters('admin|pay|choices|field_type_choices', 'get')
            this.loadSelector("type", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|field_metatype_choices', 'get')
            this.loadSelector("metaType", url)
            this.getItems();
        },
        methods: {
            deleteItem: function (id) {
                let ok = false
                makeDeleteRequest(
                    this.getDetailUrl(id)
                ).then(resp => {
                    if (resp.ok) {
                        ok = true
                        this.getItems()
                        this.closeConfirmDelete()
                    }
                    if (resp.status === 401) {
                        clearUserAuth()
                    }
                    return resp.json()
                }).then(json => {
                    if (!ok) {
                        this.errorDialog = true
                        this.errorDelete = json;
                        this.closeConfirmDelete()
                    }
                })
            },
            addItem() {
                this.closedForm()
                this.getItems();
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    this.selectors[data].push({text: 'Не указано', value: null})
                })
            },
            getItems() {
                this.loading = true
                let sys = true
                // console.log(this.URL_LIST)
                makeGetRequest(this.URL_LIST).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.items = json
                    this.loading = false
                })
            },
        }
    }


</script>
<style scoped>

</style>
