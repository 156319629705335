import helper from "./helper";


let getWorkerUrlWithParameters = helper.getWorkerUrlWithParameters


export default {
    SELECTORS: {
        WORKER: {
            USERS: () => getWorkerUrlWithParameters(
                'selectors|employee|worker',
                'get'
            )
        }
    }

}
