<template>
    <v-container>
        <v-card flat>
            <v-card-title class="title style-color-main-gray">
                <span>Поля формы</span>
                <v-spacer></v-spacer>
                <v-btn @click="opened_form = true" color="secondary" text>
                    <v-icon>add</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                @click="getItems()"
                                class="mr-2"
                                medium
                                v-on="on"
                        >
                            update
                        </v-icon>
                    </template>
                    <span>Обновить данные</span>
                </v-tooltip>
            </v-card-title>
            <v-data-table
                    :footer-props="{'items-per-page-options':[100,250,500], 'items-per-page-text': 'Число элементов'}"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    class="elevation-1"
                    no-data-text="Нет данных"
            >
                <template v-slot:item.id="{ item }">
                    #{{item.field.id}}#
                </template>
                <template v-slot:item.field="{ item }">
                    {{item.field.title}}
                </template>
                <template v-slot:item.btn="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="updateItem(item)"
                                    class="mr-2"
                                    medium
                                    v-on="on"
                            >
                                edit
                            </v-icon>
                        </template>
                        <span>Редактировать</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="openConfirmDelete(item)"
                                    medium
                                    v-on="on"
                            >
                                delete
                            </v-icon>
                        </template>
                        <span>Удалить</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <v-card-text>Данные не загружены</v-card-text>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog @input="closedForm" persistent v-model="opened_form" width="500">
            <Form :disable="true"
                  :form="urlData.form"
                  @addItem="addItem"
                  @ended="closedForm"
                  @refreshItem="addItem"
                  v-bind="selected"
                  v-if="opened_form"
            >
            </Form>
        </v-dialog>
        <v-dialog persistent v-model="errorDialog" width="500">
            <v-card>
                <v-card-title>Удаление закончилось ошибкой</v-card-title>
                <v-divider></v-divider>
                <v-card-text v-for="field in errorDelete">
                    {{field}}
                </v-card-text>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="errorDialog=false"
                                text
                        >Закрыть
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="opened_confirm" width="500">
            <v-card v-if="deletable">
                <v-card-title>Подтвердите удаление</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Вы уверены что хотите удалить?<br>
                </v-card-text>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="deleteItem(deletable.id)"
                                color="warning"
                                text
                        >
                            Удалить
                        </v-btn>
                        <v-btn
                                @click="closeConfirmDelete"
                                text
                        >Отмена
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
    import UserDataMixin from '@/mixins/UserDataMixin'
    import {makeDeleteRequest, makeGetRequest} from "@/helper/requests";
    import urls from '@/urls/helper.js'
    import Form from "@/modules/pay/page/admin/admin_pay/form_field/Form"
    import {clearUserAuth} from "@/helper";

    export default {
        name: "List",
        mixins: [UserDataMixin],
        components: {Form},
        props: {
            urlData: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                URL_LIST: urls.getPayUrlWithParameters('admin|pay|models|form_field-list', 'list', null, this.urlData),
                URL_DELETE: urls.getPayUrlWithParameters('admin|pay|models|form_field-detail', 'destroy'),
                items: [],
                selectors: {
                    field: [],
                },
                errorDelete: [],
                errorDialog: false,
                loading: true,
                headers: [
                    {text: 'Поле', sortable: false, align: 'left', value: 'field'},
                    // {text: 'id поля', sortable: false, align: 'left', value: 'id',},
                    {text: 'Сортировка', sortable: false, align: 'left', value: 'sort',},
                    {text: 'Действия', sortable: false, align: 'right', value: 'btn',},
                ]
            }
        },
        watch: {
            urlData: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    this.URL_LIST = urls.getPayUrlWithParameters('admin|pay|models|form_field-list', 'list', null, this.urlData),
                        this.URL_DELETE = urls.getPayUrlWithParameters('admin|pay|models|form_field-detail', 'destroy'),
                        this.getItems();
                }
            },
        },
        created() {

            // this.$store.commit('mainpage/changeTitle', 'Список полей');
            this.getItems();
            let url = urls.getPayUrlWithParameters('admin|pay|models|field-list', 'list')
            this.loadSelector("field", url)
        },
        methods: {
            deleteItem: function (id) {
                let ok = false
                makeDeleteRequest(
                    this.getDetailUrl(id)
                ).then(resp => {
                    if (resp.ok) {
                        ok = true
                        this.getItems()
                        this.closeConfirmDelete()
                    }
                    if (resp.status === 401) {
                        clearUserAuth()
                    }
                    return resp.json()
                }).then(json => {
                    if (!ok) {
                        this.errorDialog = true
                        this.errorDelete = json;
                        this.closeConfirmDelete()
                    }
                })
            },
            getDisplay: function (value, selector) {
                if (selector) {
                    let res = selector.find((el) => {
                        if (el.id === value)
                            return true
                    });
                    if (res) {
                        if (res.name)
                            return res.name;
                        else
                            return res.title;
                    } else
                        return null;
                } else
                    return null;
            },
            addItem() {
                this.closedForm()
                this.getItems();
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    if (data === "form")
                        this.selectors[data].push({name: 'Не указано', id: null})
                    else
                        this.selectors[data].push({title: 'Не указано', id: null})
                })
            },
            getItems() {
                this.loading = true
                let sys = true
                makeGetRequest(this.URL_LIST).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.items = json
                    this.loading = false
                })
            },
        }
    }


</script>
<style scoped>

</style>
