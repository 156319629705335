import Vue from 'vue'
import Vuex from 'vuex'
import leftmenu from './modules/leftmenu'
import maintoolbar from './modules/maintoolbar'
import mainpage from './modules/mainpage'
import user from './modules/user'
import urls from './modules/urls'
import formsaving from './modules/formsaving'


Vue.use(Vuex);

const anonimusUsername = 'AnonimusUser';

export default new Vuex.Store({
    modules: {
        leftmenu,
        maintoolbar,
        mainpage,
        user,
        urls,
        formsaving,
    },
    state: {},
    getters: {},
    mutations: {},
})
