<template>
    <v-container>
        <v-card class="pt-4 text-left" flat>
            <v-row v-if="value  && value.fio">
                <v-col class="font-weight-bold" cols="12" sm="4">Пользователь:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{value.fio}}</v-col>
            </v-row>
            <v-row v-if="value && value.totalCost">
                <v-col class="font-weight-bold" cols="12" sm="4">Стоимость:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{displayNumber()}} ₽</v-col>
            </v-row>
            <v-row v-if="value && value.maskedPan">
                <v-col class="font-weight-bold" cols="12" sm="4">Маскированный номер карты:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{value.maskedPan}}</v-col>
            </v-row>
            <v-row v-if="value  && value.bankRegisterErrorCode">
                <v-col class="font-weight-bold" cols="12" sm="4">Расширенная ошибка регистрации транзакции банком:
                </v-col>
                <v-col class="text-justify" cols="12" sm="8">{{value.bankRegisterErrorCode}}</v-col>
            </v-row>
            <v-row v-if="value  && value.bankTransactionID">
                <v-col class="font-weight-bold" cols="12" sm="4">Идентификатор транзацкии банка:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{value.bankTransactionID}}</v-col>
            </v-row>
          <v-row v-if="value  && value.type">
            <v-col class="font-weight-bold" cols="12" sm="4">Тип транзацкии:</v-col>
            <v-col class="text-justify" cols="12" sm="8">{{getDisplay(value.type,selectors.type)}}</v-col>
          </v-row>
            <v-row v-if="value  && value.chekErrorCode">
                <v-col class="font-weight-bold" cols="12" sm="4">Код ошибки отправки чека:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{value.chekErrorCode}}</v-col>
            </v-row>
            <v-row v-if="value  && value.chekErrorCode">
                <v-col class="font-weight-bold" cols="12" sm="4">Расширенная ошибка отправки чека:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{ getDisplay(value.chekErrorCode,
                    selectors.chekonline_error) }}
                </v-col>
            </v-row>
            <v-row v-if="value  && value.chekFiscalSign">
                <v-col class="font-weight-bold" cols="12" sm="4">Фискальный признак документа:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{value.chekFiscalSign}}</v-col>
            </v-row>
            <v-row v-if="value  && value.chekFiscalDocNumber">
                <v-col class="font-weight-bold" cols="12" sm="4">Номер фискального документа:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{value.chekFiscalDocNumber}}</v-col>
            </v-row>
            <v-row v-if="value  && value.chekFNSerialNumber">
                <v-col class="font-weight-bold" cols="12" sm="4">Номер фискального накопителя, в котором сформирован
                    документ:
                </v-col>
                <v-col class="text-justify" cols="12" sm="8">{{value.chekFNSerialNumber}}</v-col>
            </v-row>
            <v-row v-if="value  && value.bookkeepingErrorData">
                <v-col class="font-weight-bold" cols="12" sm="4">Ошибка выгрузки:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{ value.bookkeepingErrorData}}</v-col>
            </v-row>
            <v-divider class="ma-3"></v-divider>
            <v-row v-if="value  && value.formData.category ">
                <v-col class="font-weight-bold" cols="12" sm="4">Категория:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{ value.formData.category.name}}</v-col>
            </v-row>
            <v-row v-if="value && value.formData.name">
                <v-col class=" font-weight-bold" cols="12" sm="4">Форма:</v-col>
                <v-col class="text-justify  " cols="12" sm="8">{{value.formData.name}}</v-col>
            </v-row>
            <v-row v-if="value  && value.formData.kbk ">
                <v-col class="font-weight-bold" cols="12" sm="4">КБК:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{ value.formData.kbk}}</v-col>
            </v-row>
            <v-row v-if="value  && value.formData.fee">
                <v-col class="font-weight-bold" cols="12" sm="4">Комиссия:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{ value.formData.fee}}</v-col>
            </v-row>
            <v-row v-if="value  && value.formData.formula  && value.fio">
                <v-col class="font-weight-bold" cols="12" sm="4">Формула расчета:</v-col>
                <v-col class="text-justify " cols="12" sm="8">{{ value.formData.formula}}</v-col>
            </v-row>
            <v-row v-if="value  && value.formData.accountDefaultID  && value.fio">
                <v-col class="font-weight-bold" cols="12" sm="4">Идентификатор счета в банке:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{ value.formData.accountDefaultID}}</v-col>
            </v-row>
            <v-row v-if="value  && value.formData.taxID  && value.fio">
                <v-col class="font-weight-bold" cols="12" sm="4">Код налога:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{ getDisplay(value.formData.taxID, selectors.taxID) }}
                </v-col>
            </v-row>
            <v-row v-if="value  && value.formData.payAttribute  && value.fio">
                <v-col class="font-weight-bold" cols="12" sm="4">Признак способа расчёта:</v-col>
                <v-col class="text-justify" cols="12" sm="8">{{ getDisplay(value.formData.payAttribute,
                    selectors.payAttribute) }}
                </v-col>
            </v-row>

            <v-divider class="ma-3"></v-divider>
        </v-card>
        <div :key="field.uid" class="text-left" v-for="field in value.formData.field">
            <PublicField
                    :errors_arr="errors" :field="field" :readTr="true"
                    :savetr=true
                    :selectorValue=field.field.selectorValue
            ></PublicField>
        </div>

        <v-card-text class="text-left" v-if="value.formData.document.length > 0">Перечень нормативных документов:
        </v-card-text>
        <v-card-text :key=document.uid class="py-0" v-for="document in value.formData.document">
            <li class="text-left px-2"><a :href="document.file" target="_blank">{{ document.name }}</a></li>
        </v-card-text>
    </v-container>
</template>

<script>
  import PublicField from "@/modules/pay/components/PublicField"
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import moment from "moment";
  import UserDataMixin from "@/mixins/UserDataMixin";

  export default {
        name: "transactionDetail",
        components: {PublicField, DebugJsonComponent},
        props: {
            form: Object,
            selectors: Object
        },
        mixins: [
            UserDataMixin,
        ],
        methods: {
            displayNumber() {
                let num = 0;
                if (Number(this.value.totalCost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& '))
                    num = Number(this.value.totalCost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')
                return num
            },
            displayDate(date, minutes = false) {
                let format = 'DD.MM.YYYY';
                if (minutes)
                    format += ' HH:mm:ss';
                return moment(date).format(format)
            },
        },
        created() {
        },
        data() {
            return {
                value: this.form,
                errors: {
                    fields: {},
                    non: []
                }
            }
        },
    }
</script>

<style scoped>

</style>
