import names from '@/modules/pay/routers/names'
import {ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, PAY_INSTANCE} from "@/helper/consts";
import AdminMenu from "@/modules/pay/page/admin/AdminMenu"
import PermissionList from "@/modules/pay/page/admin/permissions/List"
import CategoryList from "@/modules/pay/page/admin/admin_pay/category/List"
import documentList from "@/modules/pay/page/admin/admin_pay/document/List"
import fieldList from "@/modules/pay/page/admin/admin_pay/field/List"
import formList from "@/modules/pay/page/admin/admin_pay/form/List"
import form_documentList from "@/modules/pay/page/admin/admin_pay/form_document/List"
import form_fieldList from "@/modules/pay/page/admin/admin_pay/form_field/List"
import selector_valueList from "@/modules/pay/page/admin/admin_pay/selector_value/List"
import transaction from "@/modules/pay/page/admin/admin_pay/transaction/List"

export default ([
    {
        path: '/admin',
        name: names.ADMIN._BASE,
        component: AdminMenu,
        meta: {
            breadcrumb: 'Администрирование',
            requiredAuth: true,
            instances: [
                PAY_INSTANCE,
                ACCOUNTS_INSTANCE
            ],
        },
        children: [
            {
                path: 'permissions',
                name: names.ADMIN.PERMISSION,
                component: PermissionList,
                meta: {
                    breadcrumb: 'Список прав доступа',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE,
                        ORGANIZATION_INSTANCE
                    ],
                },
            },
            {
                path: 'category',
                name: names.ADMIN.CATEGORY,
                component: CategoryList,
                meta: {
                    breadcrumb: 'Список категорий',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ],
                },
            },
            {
                path: 'all/transaction',
                name: names.ADMIN.TRANSACTION,
                component: transaction,
                meta: {
                    breadcrumb: 'Список всех транзакций',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ],
                },
            },
            {
                path: 'document',
                name: names.ADMIN.DOCUMENT,
                component: documentList,
                meta: {
                    breadcrumb: 'Список документов',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ],
                },
            },
            {
                path: 'field',
                name: names.ADMIN.FIELD,
                component: fieldList,
                meta: {
                    breadcrumb: 'Список полей',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ],
                },
            },
            {
                path: 'form',
                name: names.ADMIN.FORM,
                component: formList,
                meta: {
                    breadcrumb: 'Список форм',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ],
                },
            },
            {
                path: 'form_document',
                name: names.ADMIN.FORM_DOCUMENT,
                component: form_documentList,
                meta: {
                    breadcrumb: 'Список документов формы',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ],
                },
            },
            {
                path: 'form_field',
                name: names.ADMIN.FORM_FIELD,
                component: form_fieldList,
                meta: {
                    breadcrumb: 'Список полей форм',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ],
                },
            },
            {
                path: 'selector',
                name: names.ADMIN.SELECTOR_VALUE,
                component: selector_valueList,
                meta: {
                    breadcrumb: 'Список перечней выбора',
                    requiredAuth: true,
                    instances: [
                        PAY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ],
                },
            }
        ]
    }
])
