<template>
    <div class="page-content" style="margin: 0px; width: 100%">
        <v-container>
            <v-card flat>
                <v-card-title class="title style-color-main-gray">
                    <span>Мои транзакции</span>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="getItems()"
                                    class="mr-2"
                                    medium
                                    v-on="on"
                            >
                                update
                            </v-icon>
                        </template>
                        <span>Обновить данные</span>
                    </v-tooltip>
                </v-card-title>

                <v-data-table
                        :expanded.sync="expanded"
                        :footer-props="{'items-per-page-options':[100, 250, 500], 'items-per-page-text': 'Число элементов'}"
                        :headers="headers"
                        :items="items"
                        :items-per-page.sync="pagination.size"
                        :page.sync="pagination.page"
                        :server-items-length="pagination.count"
                        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                        class="row-pointer"
                        item-key="uid"
                        no-data-text="Данные отсутствуют"
                        no-results-text="Ничего не было найдено"
                        show-expand
                        single-expand
                >
                    <template v-slot:item.bankCheck="{  item }">
                        <td >
                            <v-icon v-if="item.bankCheck">done</v-icon>
                            <v-icon v-else>clear</v-icon>
                        </td>
                    </template>
                    <template v-slot:item.bankRegister="{  item }">
                        <td >
                            <v-icon v-if="item.bankRegister">done</v-icon>
                            <v-icon v-else>clear</v-icon>
                        </td>
                    </template>
                    <template v-slot:item.chekSent="{  item }">
                        <td >
                            <v-icon v-if="item.chekSent">done</v-icon>
                            <v-icon v-else>clear</v-icon>
                        </td>
                    </template>
                    <template v-slot:item.name="{  item }">
                        <td >
                            {{item.formData.name}}
                        </td>
                    </template>
                    <template v-slot:item.startDateTime="{  item }">
                        <td  v-if="item.startDateTime">
                            {{ displayDate(item.startDateTime,true)}}
                        </td>
                    </template>
                    <template v-slot:item.bankCheckDateTime="{  item }">
                        <td  v-if="item.bankCheckDateTime">
                            {{ displayDate(item.bankCheckDateTime,true)}}
                        </td>
                    </template>
                    <template v-slot:item.bankRegisterDateTime="{  item }">
                        <td  v-if="item.bankRegisterDateTime">
                            {{ displayDate(item.bankRegisterDateTime,true)}}
                        </td>
                    </template>
                    <template v-slot:item.chekSentDateTime="{  item }">
                        <td  v-if="item.chekSentDateTime">
                            {{ displayDate(item.chekSentDateTime,true)}}
                        </td>
                    </template>
                    <template v-slot:item.taxID="{ item }">
                        {{ getDisplay(item.formData.taxID, selectors.taxID) }}
                    </template>
                    <template v-slot:item.payAttribute="{ item }">
                        {{ getDisplay(item.formData.payAttribute, selectors.payAttribute) }}
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <TransactionDetail :form="item" :selectors="selectors"></TransactionDetail>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>

<script>

    import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
    import UserDataMixin from '@/mixins/UserDataMixin'
    import urls from '@/urls/pay'
    import choices from '@/urls/choices'
    import {loadData, makeGetRequest} from "@/helper/requests";
    import names from "@/modules/pay/routers/names";
    import {getInstMenu} from "@/helper/instances";
    import {isAuthenticated} from "@/helper/auth";
    import TransactionDetail from "@/modules/pay/components/TransactionDetail"
    import moment from "moment";
    import store from "@/store";

    export default {
        name: "List",
        mixins: [
            PaginatedDataMapperMixin,
            UserDataMixin
        ],
        components: {
            TransactionDetail
        },
        props: {},
        data() {
            return {
                selectors: {
                    taxID: [],
                    payAttribute: [],
                    chekonline_error: [],
                },
                expanded: [],
                items: [],
                pagination: {},
                URL_LIST: urls.USER.TRANSACTION.LIST(),
                URL_DELETE: urls.USER.TRANSACTION.DETAIL(),
                headers: [
                    {text: 'Индефикатор трназакции', sortable: false, align: 'left', value: "uid"},
                    {text: 'Услуга', sortable: false, align: 'left', value: "formData.name"},
                    {text: 'Дата инициализации', sortable: false, align: 'left', value: "startDateTime"},
                    {text: 'Проверка', sortable: false, align: 'left', value: "bankCheck"},
                    {text: 'Дата проверки', sortable: false, align: 'left', value: "bankCheckDateTime"},
                    {text: 'Регистрация', sortable: false, align: 'left', value: "bankRegister"},
                    {text: 'Дата регистрации', sortable: false, align: 'left', value: "bankRegisterDateTime"},
                    {text: 'Чек отправлен', sortable: false, align: 'left', value: "chekSent"},
                    {text: 'Дата отправки чека', sortable: false, align: 'left', value: "chekSentDateTime"},
                    {text: '', value: 'data-table-expand'},
                ],
            }
        },
        watch: {
            pagination: {
                handler() {
                    this.getItems()
                },
                deep: true
            },
        },
        methods: {
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                })
            },
            displayDate(date, minutes = false) {
                let format = 'DD.MM.YYYY';
                if (minutes)
                    format += ' HH:mm:ss';
                return moment(date).format(format)
            },

            loadLeftmenu() {
                this.$store.commit('mainpage/changeTitle', 'Онлайн оплата услуг');
                if (isAuthenticated()) {
                    this.$store.commit('leftmenu/replaceInstances', getInstMenu());
                    this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
                } else {
                    this.$store.commit('leftmenu/dissable');
                }
            },
            getLeftMenu() {
                let with_category_uid = (name) => {
                    return {name: name, params: {category: this.$route.params.category}}
                };
                let menu = []
                menu.push({
                    title: 'Перечень категорий',
                    icon: 'view_list',
                    included: true,
                    router: with_category_uid(names.BASE),
                })
                menu.push({
                    title: 'Мои транзакции',
                    icon: 'local_atm',
                    included: false,
                    router: names.PUBLIC.TRANZACTION,
                    name: names.PUBLIC.TRANZACTION,
                })
                // menu.push({
                //   title: 'Услуги печати',
                //   icon: 'print',
                //   included: false,
                //   router: names.PUBLIC.PRINT,
                //   name: names.PUBLIC.PRINT,
                // })
                return menu
            },
            getItems() {
                this.closedForm()
                this.load();
            },
            addItem() {
                this.closedForm()
                this.load();
            },
            refreshItem() {
                this.closedForm()
                this.load();
            },
            loadData(url = undefined) {
                this.load(url);
            },
            load: function (url = undefined) {
                if (url === undefined)
                    url = this.URL_LIST,
                        loadData(
                            url,
                            (data) => {
                                this.mapPagination(data);
                                this.mapResults(data, 'items');
                                this.setCurrentLoadUrl(url)
                            }
                        )
            },
        },

        created() {
            if (!(store.getters['urls/getAllowed']( 'pay', 'public|pay|models|transaction-list', 'list')))
              document.location.href = `${process.env.VUE_APP_LOGIN_HOST}?from=${document.location.href}`;
            let url = choices.CHOICES.PAY_ATTRIBUTE()
            this.loadSelector("payAttribute", url)
            url = choices.CHOICES.TAX_ID()
            this.loadSelector("taxID", url)
            url = choices.CHOICES.CHEKONLINE_ERROR_CODE()
            this.loadSelector("chekonline_error", url)

            if (isAuthenticated()) {
                this.$store.commit('leftmenu/enableLeftMenu');
                // this.$store.commit('leftmenu/hide');
                this.getItems()
            }else {
              document.location.href = `${process.env.VUE_APP_LOGIN_HOST}?from=${document.location.href}`;
            }
            this.loadLeftmenu()
        }
    }
</script>

<style scoped>
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }
</style>
