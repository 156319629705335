<template>
    <div>
        <v-layout justify-center>
            <v-flex>
                <v-card ref="form">
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>
                            <span v-if="!!id">Изменить запись</span>
                            <span v-else> <div>Выдать права</div></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-toolbar-items>
                                <v-btn @click="closeDialog" class="pa-0 ma-0" icon>
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                      <loading-autocomplete
                          placeholder="Введите ФИО и выберете из предложенных вариантов"
                          label="Сотрудник"
                          v-model="value.user"
                          search-parameter="fio"
                          :post-loader="postLoader"
                          :url="department_selector"
                          :error="hasError('user')"
                          :errorMessages="getError('user')"
                      >
                        <template v-slot:no-data>
                          <div>
                            Для поиска начните набирать ФИО, <br> и либо остановитесь на 1 секунду, либо нажмите Enter
                          </div>
                        </template>
                      </loading-autocomplete>
                        <v-autocomplete
                                :error="hasError('role')"
                                :error-messages="getError('role')"
                                :items="selectors.role"
                                label="Роли"
                                no-data-text="Нет данных"
                                v-model="value.role"
                        ></v-autocomplete>
                        <v-alert type="error" v-if="hasError('non_field_errors')">
                            {{getErrorArray('non_field_errors')}}
                        </v-alert>
                        <v-alert type="error" v-if="hasError('detail')">
                            {{getErrorArray('detail')}}
                        </v-alert>
                        <v-btn @click="apply" color="primary">Сохранить</v-btn>
                    </v-card-text>
                    <v-divider class="mt-5"></v-divider>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import urls from '@/urls/helper.js'
    import selectors from "@/urls/selectors";
    import {makeGetRequest, makePostRequest} from "@/helper/requests";
    import UserDataFormMixin from '@/mixins/UserDataFormMixin'
    import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
    import {generateListUidToName} from "@/helper/uidToName";
    export default {
        name: "Role",
        mixins: [UserDataFormMixin],
        props: ['id', 'user', 'role'],
        components: {
          LoadingAutocomplete
        },
        computed: {
          department_selector(){
            return selectors.SELECTORS.WORKER.USERS()
          },
        },
        data() {
            return {
                URL_CREATE: urls.getPayUrlWithParameters('admin|permissions|models|role_user-list', 'list'),
                URL_UPDATE: urls.getPayUrlWithParameters('admin|permissions|models|role_user-detail', 'update'),
                value: {
                    id: this.id || '',
                    user: this.user || '',
                    role: this.role || false,
                },
                selectors: {
                    responsible: [],
                    role: [],
                },
            }
        },
        methods: {
          postLoader(data, list, end) {
            data.results.forEach(el => {
              this.$set(el, 'fio', '');
            })
            let vals = list(data.results);
            generateListUidToName(
                vals,
                "value",
                "fio",
                ()=>{
                  vals.forEach(el => {
                    this.$set(el, 'text', `${el.fio}, ${el.text}`)
                  })
                  end();
                }
            )
          },
            closeDialog() {
                this.$emit('ended', '')
            },
            loadSelector() {
                let url = urls.getPayUrlWithParameters('admin|permissions|choices|role_choices', 'get')
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors.role = json
                })
            },
        },
        created() {
            this.loadSelector();
        }
    }
</script>

<style scoped>
</style>
