<template>
    <div>
        <v-layout justify-center>
            <v-flex>
                <v-card ref="form">
                    <v-toolbar color="primary" dark style="position: sticky; z-index: 10000;top: 0">
                        <v-toolbar-title>
                            <span v-if="!!id">Изменить категорию</span>
                            <span v-else> <div>Добавить категорию</div></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-toolbar-items>
                                <v-btn @click="closeDialog" class="pa-0 ma-0" dark icon style="left: +16px;">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>

                        <v-text-field :error="hasError('name')"
                                      :error-messages="getError('name')"
                                      label="Категория"
                                      v-model="value.name"
                        >
                            <template slot="label">Категория<span class="red--text">*</span>:</template>
                        </v-text-field>
                        <v-text-field :error="hasError('description')"
                                      :error-messages="getError('description')"
                                      label="Описание"
                                      v-model="value.description"
                        ></v-text-field>
                        <v-text-field :error="hasError('sort')" :error-messages="getError('sort')"
                                      label="Сортировка"
                                      type="number"
                                      v-model.number="value.sort"
                        >
                            <template slot="label">Сортировка<span class="red--text">*</span>:</template>
                        </v-text-field>
                        <v-autocomplete
                                :error="hasError('parentCategory')"
                                :error-messages="getError('parentCategory')"
                                :items="selectors.parentCategory"
                                item-text="name"
                                item-value="id"
                                label="Родитель"
                                v-model="value.parentCategory">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.name}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-file-input
                                :error="hasError('image')"
                                :error-messages="getError('image')"
                                accept="image/jpeg,image/png"
                                label="Файл"
                                v-if="typeof value.image !== 'string'"
                                v-model="value.image"
                        ></v-file-input>

                        <v-row class="text-left mb-4" v-else>
                            <v-col class="text-left py-0" cols="12">
                                Файл
                            </v-col>
                            <v-col>
                                <a :href="value.image" target="_blank">{{value.image}}</a>
                            </v-col>
                            <v-col cols="2">
                                <v-btn @click="value.image=null" icon>
                                    <v-icon>clear</v-icon>
                                </v-btn>
                            </v-col>

                        </v-row>
                        <v-alert type="error" v-if="hasError('non_field_errors')">
                            {{getError('non_field_errors')}}
                        </v-alert>
                        <v-alert type="error" v-if="hasError('detail')">
                            {{getError('detail')}}
                        </v-alert>
                        <v-btn @click="applyFile" color="primary">Сохранить</v-btn>
                    </v-card-text>

                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

  import UserDataFormMixin from '@/mixins/UserDataFormMixin'
  import {makeGetRequest, makePostRequestFile} from "@/helper/requests";
  import urls from '@/urls/helper.js'

  export default {
        name: "Courses",
        mixins: [UserDataFormMixin],
        props: {
            id: Number,
            sort: Number,
            name: String,
            parentCategory: String,
            description: String,
            image: String,
        },
        data() {
            return {
                URL_CREATE: urls.getPayUrlWithParameters('admin|pay|models|category-list', 'create'),
                URL_UPDATE: urls.getPayUrlWithParameters('admin|pay|models|category-detail', 'update'),
                selectors: {
                    parentCategory: [],
                },
                value: {
                    id: this.id || undefined,
                    name: this.name || '',
                    description: this.description || '',
                    sort: this.sort || 0,
                    parentCategory: this.parentCategory || null,
                    image: this.image || null,
                },
            }
        },
        methods: {
            applyFile() {
                let url = this.URL_CREATE
                let method = 'POST'
                if (this.id) {
                    url = urls.getPayUrlWithParameters('admin|pay|models|category-detail', 'update', {id: this.id})
                    method = 'PATCH'
                }
                if ((typeof this.value.image === 'object' && this.value.image !== null)
                    || typeof this.value.image === 'string') {
                    var formData = new FormData();
                    if (typeof this.value.image !== 'string')
                        formData.append('image', this.value.image)
                    formData.append('name', this.value.name)
                    formData.append('description', this.value.description)
                    formData.append('sort', this.value.sort)
                    if (this.value.parentCategory !== null)
                        formData.append('parentCategory', this.value.parentCategory)

                    let status = true;

                    makePostRequestFile(url, formData, method)
                        .then(resp => {
                            if (!resp.ok) {
                                status = false;
                            }
                            return resp.json();
                        })
                        .then(json => {
                            if (status) {
                                this.$emit('addItem', json);
                            } else {
                                this.receiveErrors(json);
                            }
                        })
                } else {
                    this.apply()
                }
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    this.selectors[data].push({name: 'Не указано', id: null})
                })
            },
            closeDialog() {
                this.$emit('ended', '')
            },
        },
        created() {
            let url = urls.getPayUrlWithParameters('admin|pay|models|category-list', 'list')
            this.loadSelector("parentCategory", url)
        }
    }

</script>

<style scoped>

</style>
