import names from "@/modules/pay/routers/names";
import Transaction from "@/modules/pay/page/user/Transaction";
import PrintPay from "@/modules/pay/page/user/PrintPay";
import {ACCOUNTS_INSTANCE, PAY_INSTANCE} from "@/helper/consts";

export default ([
    {
        path: '/transaction',
        name: names.PUBLIC.TRANZACTION,
        component: Transaction,
        meta: {
            breadcrumb: 'Транзакции пользователя',
            requiredAuth: true,
            instances: [
                PAY_INSTANCE,
                ACCOUNTS_INSTANCE
            ],
        }
    },
    {
        path: '/print',
        name: names.PUBLIC.PRINT,
        component: PrintPay,
        meta: {
            breadcrumb: 'Услуги печати',
            requiredAuth: true,
            instances: [
                PAY_INSTANCE,
                ACCOUNTS_INSTANCE
            ],
        }
    }
])
