<template>
    <v-container style="height: 100%">
        <div class="pb-4">
            <v-expansion-panels>
                <v-expansion-panel
                >
                    <v-expansion-panel-header>
                        <div>
                            <v-icon>search</v-icon>
                            Поиск
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-0">
                        <v-text-field
                                dense
                                outlined
                                placeholder="Поиск"
                                v-model="search"
                        >
                        </v-text-field>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn @click="search=''">Сбросить фильтр</v-btn>
                        </v-card-actions>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <v-card flat>
            <v-card-title class="title style-color-main-gray">
                <span>Список категорий</span>
                <v-spacer></v-spacer>

                <v-btn @click="opened_form = true" color="secondary" text>
                    <v-icon>add</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                @click="getItems()"
                                class="mr-2"
                                medium
                                v-on="on"
                        >
                            update
                        </v-icon>
                    </template>
                    <span>Обновить данные</span>
                </v-tooltip>
            </v-card-title>
            <v-data-table
                    :footer-props="{'items-per-page-options':[100,250,500], 'items-per-page-text': 'Число элементов'}"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :search="search"
                    class="elevation-1"
                    group-by="parentCategory"
                    no-data-text="Нет данных"
            >
                <template v-slot:group.header="item">
                    <td :colspan="headers.length" class="text-left">
                        <v-btn @click="item.toggle" icon>
                            <v-icon>{{ item.isOpen ? 'remove' : 'add' }}</v-icon>
                        </v-btn>
                        {{ getDisplay(item.group, selectors.parentCategory) }}

                    </td>
                </template>
                <template v-slot:item.image="{item}">
                    <a :href="item.image" target="_blank">{{item.image}}</a>
                </template>
                <template v-slot:item.btn="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="updateItem(item)"
                                    class="mr-2"
                                    medium
                                    v-on="on"
                            >
                                edit
                            </v-icon>
                        </template>
                        <span>Редактировать</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="openConfirmDelete(item)"
                                    medium
                                    v-on="on"
                            >
                                delete
                            </v-icon>
                        </template>
                        <span>Удалить</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <v-card-text>Данные не загружены</v-card-text>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog @input="closedForm" persistent v-model="opened_form" width="500">
            <Form @addItem="addItem"
                  @ended="closedForm"
                  @refreshItem="refreshItem"
                  v-bind="selected"
                  v-bind:info="$route.params.id"
                  v-if="opened_form"
            >
            </Form>
        </v-dialog>

        <v-dialog persistent v-model="opened_confirm" width="500">
            <v-card v-if="deletable">
                <v-card-title>Подтвердите удаление категории</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Вы уверены что хотите удалить {{deletable.name}} ?<br>
                </v-card-text>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="deleteItem(deletable.id)"
                                color="warning"
                                text
                        >
                            Удалить
                        </v-btn>
                        <v-btn
                                @click="closeConfirmDelete"
                                text
                        >Отмена
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
    import UserDataMixin from '@/mixins/UserDataMixin'
    import {makeGetRequest} from "@/helper/requests";
    import urls from '@/urls/helper.js'
    import Form from "./Form"

    export default {
        name: "List",
        mixins: [UserDataMixin],
        components: {Form},
        data() {
            return {
                URL_LIST: urls.getPayUrlWithParameters('admin|pay|models|category-list', 'list'),
                URL_DELETE: urls.getPayUrlWithParameters('admin|pay|models|category-detail', 'destroy'),
                items: [],
                search: '',
                selectors: {
                    parentCategory: [],
                },
                loading: true,
                headers: [
                    {text: 'Название', sortable: false, align: 'left', value: "name",},
                    {text: 'Родитель', sortable: false, align: 'left', value: "parentCategory",},
                    {text: 'Изображение', sortable: false, align: 'left', value: "image",},
                    {text: 'Сортировка', sortable: false, align: 'left', value: "sort",},
                    {text: 'Действия', sortable: false, align: 'right', value: "btn",},
                ]
            }
        },

        created() {
            // this.$store.commit('mainpage/changeTitle', 'Список категорий');
            this.getItems();
        },
        methods: {
            getDisplay: function (value, selector) {
                if (selector) {
                    let res = selector.find((el) => {
                        if (el.id === value)
                            return true
                    });
                    if (res) {
                        return res.name;
                    } else
                        return null;
                } else
                    return null;
            },
            addItem() {
                this.closedForm()
                this.getItems();
            },

            getItems() {
                this.loading = true
                let sys = true
                makeGetRequest(this.URL_LIST).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.items = json
                    this.loading = false
                    this.selectors.parentCategory = json
                })
            },
        }
    }


</script>
<style scoped>

</style>
