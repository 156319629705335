import { render, staticRenderFns } from "./List.vue?vue&type=template&id=37c6d52a&scoped=true"
import script from "./List.vue?vue&type=script&lang=js"
export * from "./List.vue?vue&type=script&lang=js"
import style0 from "./List.vue?vue&type=style&index=0&id=37c6d52a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c6d52a",
  null
  
)

export default component.exports