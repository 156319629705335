<template>
    <div>
        <v-layout justify-center>
            <v-flex>
                <v-card ref="form">
                    <v-toolbar color="primary" dark style="position: sticky; z-index: 10000;top: 0">
                        <v-toolbar-title>
                            <span v-if="!!id">Изменить документ</span>
                            <span v-else> <div>Добавить документ</div></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-toolbar-items>
                                <v-btn @click="closeDialog" class="pa-0 ma-0" dark icon style="left: +16px;">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-file-input
                                :error="hasError('file')"
                                :error-messages="getError('file')"
                                accept="application/pdf"
                                label="Файл"
                                v-model="value.file"
                        ></v-file-input>
                        <v-text-field
                                :error="hasError('name')"
                                :error-messages="getError('name')"
                                class="pl-8"
                                label="Название файла"
                                v-model="value.name"
                        ></v-text-field>
                        <v-alert type="error" v-if="hasError('non_field_errors')">
                            {{getError('non_field_errors')}}
                        </v-alert>
                        <v-alert type="error" v-if="hasError('detail')">
                            {{getError('detail')}}
                        </v-alert>
                        <v-btn @click="apply" color="primary">Сохранить</v-btn>
                    </v-card-text>

                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

    import UserDataFormMixin from '@/mixins/UserDataFormMixin'
    import {makePostRequestFile} from "@/helper/requests";
    import urls from '@/urls/helper.js'

    export default {
        name: "Courses",
        mixins: [UserDataFormMixin],
        props: {
            id: Number,
            name: String,
            file: String,
        },
        data() {
            return {
                URL_CREATE: urls.getPayUrlWithParameters('admin|core|models|document-list', 'create'),
                URL_UPDATE: urls.getPayUrlWithParameters('admin|core|models|document-detail', 'update'),
                selectors: {
                    level: [],
                },
                value: {
                    id: this.id || '',
                    file: null,
                    name: this.name || '',
                },
            }
        },
        methods: {
            apply() {
                let url = this.URL_CREATE
                let method = 'POST'
                if (this.id) {
                    url = urls.getPayUrlWithParameters('admin|core|models|document-detail', 'update', {id: this.id})
                    method = 'PATCH'
                }
                var formData = new FormData();

                if (typeof this.value.file === 'object' && this.value.file !== null) {
                    formData.append('file', this.value.file)
                }
                formData.append('name', this.value.name)

                let status = true;

                makePostRequestFile(url, formData, method)
                    .then(resp => {
                        if (!resp.ok) {
                            status = false;
                        }
                        return resp.json();
                    })
                    .then(json => {
                        if (status) {
                            this.$emit('addItem', json);
                        } else {
                            this.receiveErrors(json);
                        }
                    })
            },

            closeDialog() {
                this.$emit('ended', '')
            },
        },
        created() {
            // let url = urls.getPayUrlWithParameters('admin|pay|models|category-list', 'list')
            // this.loadSelector("level",url)
        }
    }

</script>

<style scoped>

</style>
