<template>
    <v-container class="py-0 px-0" fluid v-if="form">
        <v-card-title
                class="subprimary headline">
            <v-row class="text-left">
                <v-col>
                    <div style="word-break:break-word">
                        <v-btn
                                @click="returnCategory()" class="subprimary black--text" icon small>
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        {{currentName()}}
                    </div>
                </v-col>
            </v-row>
        </v-card-title>
        <v-row class="justify-center align-center">
            <v-col cols="12" lg="9" md="10" sm="11" xl="8" xs="12">
                <v-card flat>
                    <v-card-text id="error">
                        <div :id="'error_'+field.field.id" :key="field.uid" v-for="field in form.field">
                            <PublicField
                                    :errors_arr="errors"
                                    :selectorValue=selectorValue
                                    @updateName="updateName($event)"
                                    v-bind:field="field"
                                    v-bind:newValCheckBox="isNameSame"
                                    v-on:update:obj="updateObj(field,$event)"
                            ></PublicField>
                        </div>
                    </v-card-text>

                    <v-card-text class="text-left" v-if="form.document.length > 0">Перечень нормативных документов:
                    </v-card-text>
                    <v-card-text :key=document.uid class="py-0" v-for="document in form.document">
                        <li class="text-left px-2"><a :href="document.file" target="_blank">{{ document.name }}</a></li>
                    </v-card-text>
                    <v-alert outlined type="error" v-if="hasError('totalCost')">{{getError('totalCost')}}</v-alert>
                    <v-alert outlined type="error" v-if="hasError('formData')">{{getError('formData')}}</v-alert>
                    <v-alert type="error" v-if="hasError('non_field_errors')">
                        {{getError('non_field_errors')}}
                    </v-alert>
                    <v-alert type="error" v-if="hasError('detail')">
                        {{getError('detail')}}
                    </v-alert>
                    <v-card-text class="d-flex title justify-center align-center">
                        Итого: {{ (calculateSum).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')}} ₽
                    </v-card-text>
                    <v-card-text>

                        <v-alert type="error" v-if="Object.keys(errors).length > 0">
                            Возникла ошибка, проверьте заполнение формы
                        </v-alert>

                        <v-row  class="  justify-center align-center">
<!--                          <v-btn min-width="215"-->
<!--                                 style="background: linear-gradient( 235deg , #5b57a2, #ef8019, #0698d6);"-->
<!--                                 :loading="startPay" @click="sendTransaction(1)" class="primary mx-1 my-1">Оплатить через сбп</v-btn>-->

                          <v-btn min-width="215"
                                 :loading="startPay" @click="sendTransaction(0)" class="primary mx-1 my-1">Оплатить картой</v-btn>
                        </v-row>


                        <div class="pt-10">
                          <v-alert type="info" outlined class="text-left">
                            <v-card-text class="caption py-0"> После нажатия кнопки «оплатить» Вы будете перенаправлены на защищенную платежную страницу
                              "Газпромбанк" (Акционерное общество), где будет необходимо ввести данные Вашей пластиковой карты.
                            </v-card-text>
                            <v-card-text class="caption py-0"> В случае успешной авторизации
                              Вы получите от сайта уведомление о том, что оплата проведена и/или описание порядка получения товара/услуги.</v-card-text>
                          </v-alert>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <div class="d-flex justify-center align-center" v-else>
      <semipolar-spinner
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
</template>

<script>

  import {SemipolarSpinner} from 'epic-spinners'
  import names from "@/modules/pay/routers/names";
  import urls from "@/urls/pay";
  import {loadData, makePostRequest} from "@/helper/requests";
  import {getUserUid} from "@/helper";
  import {isAuthenticated} from "@/helper/auth";
  import PublicField from "@/modules/pay/components/PublicField"
  import UserDataFormMixin from '@/mixins/UserDataFormMixin'
  import accounts from "@/urls/accounts";

  export default {
        name: "PayForm",
        components: {
            PublicField,
            SemipolarSpinner
        },
        mixins: [UserDataFormMixin],
        computed: {
          /**
           * Расчет стоимости в зависимости пришедших от полей
           */
            calculateSum() {
                let answ = 0
                try {
                    let arrID = []
                    let formula = this.form.formula
                    if (this.form) {
                        for (let obj of this.form.field) {
                            if (obj.field.type === "5") {
                                let val = obj.field.selectorValue.find(currentValue => {
                                    return currentValue.uid === obj.field.defaultValue
                                })
                                if (val) {
                                    arrID.push({id: "#" + String(obj.field.id) + "#", value: val.value})
                                }
                            } else {
                                //для полей не селектра
                                arrID.push({
                                    id: "#" + String(obj.field.id) + "#",
                                    value: (Number(obj.field.defaultValue))
                                })
                            }

                        }
                    }
                    //подстановка значений в формулу
                    for (let str of arrID) {
                        let re = new RegExp(str.id, 'g');
                        formula = formula.replace(re, str.value);
                    }
                    //расчет значения
                    answ = eval(formula)
                    return answ
                } catch (e) {
                    return 0
                }
            // eslint-disable-next-line no-unreachable
                return answ
            },
        },
        watch: {
          /**
           * заполнение данных селектора
           */
            "form.field": {
                handler(newTxData, oldTxData) {
                    this.selectorValue = [null]
                    for (let obj of this.form.field) {
                        //
                        if (obj.field.type === "5") {
                            if (this.selectorValue.indexOf(obj.field.defaultValue) === -1) {
                                // Выполнение кода, если элемент в массиве не найден
                                this.selectorValue.push(obj.field.defaultValue)
                            }
                            // console.log(obj.field.defaultValue)
                        }
                    }
                },
                deep: true
            },
        },
        data() {
            return {
                form: null,
                startPay: false,
                userInfo: null,
                selectorValue: [null],
                sameField: [],
                isNameSame: false,
            }
        },

        methods: {

          /**
           * Получение текущего имени категории
           *  @param {boolean} type - тип транзакции
           */

            sendTransaction(type=0) {
              this.saveFormInStorage()
              let success = true
            //проверка валидации форм
                if (this.form) {
                    for (let val in this.form.field) {
                        if (!this.form.field[val].field.validVal) {
                            this.$vuetify.goTo('#error' + '_' + this.form.field[val].field.id)
                            success = false
                        }

                    }
                }
                if (success) {
                    this.startPay = true
                    this.errors = {}
                    let data = {
                        form: this.form.uid,
                        totalCost: this.calculateSum,
                        fields: {},
                        type:String(type)

                    }
                    for (let obj of this.form.field) {
                        if (obj.field.type === "0") {
                            data.fields[String(obj.field.uid)] = Number(obj.field.defaultValue)
                        } else {
                            data.fields[String(obj.field.uid)] = obj.field.defaultValue
                        }

                    }
                    let access = true
                    let url = urls.PUBLIC.TRANSACTION.START()
                    makePostRequest(url, data).then(resp => {
                        this.startPay = false
                        if (!resp.ok) {
                            access = false
                        }
                        return resp.json()
                    }).then(json => {
                        if (access) {
                            document.location.href = json
                        } else {
                            this.errors = json
                            this.$vuetify.goTo('#error')
                        }
                    })
                }


            },
          /**
           * Получение текущего имени категории
           *  @param {boolean} isCopy - копирование поля фио
           */
            updateName(isCopy) {
                let originName = this.form.field.find(item => item.field.metaType == '2');
                if (originName) {
                    let copyName = this.form.field.find(item => item.field.metaType == '3');
                    if (copyName) {
                        if (isCopy)
                            copyName.field.defaultValue = originName.field.defaultValue
                        else{
                          let urllocation = new URL(window.location.href)
                          if(Array.from(urllocation.searchParams).length == 0){
                            copyName.field.defaultValue = ''
                        }
                    }
                    }
                }
            },
          /**
           * Получение текущего имени категории
           *  @param {object} field - поле
           *   @param {object} item - новое значение поля
           */
            updateObj(field, item) {
                field = item
            },

            /**
             * Получение текущего имени категории
             */
            currentName() {
                if (this.form) {
                    return this.form.name
                }
                return ""
            },
          /**
           * Роутинг по назад при нажатии на кнопку
           */
            returnCategory() {
                // сохраняем во вью сторе перед возвращением из формы назад по кнопке на форме
                this.saveForm()

                if (this.form) {
                    if (this.form.category !== null)
                        this.$router.push({name: names.PUBLIC.CATEGORY, params: {category: this.form.category.uid}})
                    else {
                        this.$router.push({name: names.BASE})
                    }
                }
            },
          /**
           * Подгрузка данных пользователя при аторизированном входе
           */
            loadUserInfo() {
              if (isAuthenticated()) {
              let urllocation = new URL(window.location.href)
                if(Array.from(urllocation.searchParams).length > 0 ){
                  this.loadGetParams()
                  if(!this.form.printPay)
                   return 1
              }
                    // распаковка всех сохраненных данных и пропуск заполнения авторизационных данных.
                    // если данных нет return 0 и заполняются авторизационные данные
                if(!this.form.printPay)
                    if (this.checkDataForm()) return 1
                    let url = accounts.PROFILE.DETAIL(getUserUid())
                    loadData(url, (json) => {
                        this.userInfo = json
                        for (let val in this.form.field) {
                            if (this.form.field[val].field.metaType === '0')
                                this.form.field[val].field.defaultValue = this.userInfo.email

                            if (this.form.field[val].field.metaType === '1') {
                                for (let num in this.userInfo.phones) {
                                    this.form.field[val].field.defaultValue = this.userInfo.phones[num].code + this.userInfo.phones[num].number
                                }
                            }
                            if (this.form.field[val].field.metaType === '2')
                                this.form.field[val].field.defaultValue = this.userInfo.lastname + ' ' + this.userInfo.firstname + ' ' + this.userInfo.midname
                        }
                        //подгрузка гет параметров
                      this.loadGetParams()
                    }, (err) => {
                        // this.$router.push({name: names.BASE})
                    })
                }
                else {
                  this.loadGetParams()

                }
            },
          /**
           * Подгрузка данных из гет параметром при переходе по штрих коду
           */
            loadGetParams(){

              let url = new URL(window.location.href)
              for (let [name, value] of url.searchParams) {
                for (let val2 in this.form.field) {
                  let splits = name.split('_');
                  if (splits.length === 2){
                    if(splits[0]==='ID'){
                      if (this.form.field[val2].field.id == splits[1]) {
                        this.form.field[val2].field.defaultValue = value
                      }
                    }
                    if(splits[0]==='METATYPE'){
                      if (this.form.field[val2].field.metaType == splits[1]) {
                        this.form.field[val2].field.defaultValue = value
                      }
                    }
                  }
                }
              }
            },
          /**
           * Подгрузка формы
           */
            loadForm() {
                let url = urls.PUBLIC.FORM.DETAIL({uid: this.$route.params.form}, null,)
                if(!url)
                    this.$router.push({name: names.BASE})
                loadData(url, (json) => {
                    for (let val in json.field) {
                        json.field[val].field.validVal = true
                    }
                    this.form = json
                    this.loadUserInfo();
                }, (err) => {
                    this.$router.push({name: names.BASE})
                })
            },
          /**
           * Подгрузка формы из локал строраджа
           */
            checkDataForm() {
              // Есть ли уид формы во вью стор
              if (this.$store.state.formsaving.formUid === undefined) {
                  // Есть ли уид формы в локал сторедж
                  // запоминаем во вью стор в любом случае
                  if (window.localStorage.getItem('formUid') !== null)
                      this.$store.commit('formsaving/saveUid', window.localStorage.getItem('formUid'))
                  else
                      this.$store.commit('formsaving/saveUid', this.form['uid'])
              }
              else if (this.form['uid'] !== this.$store.state.formsaving.formUid) {
                  // Там что то есть, сравниваем то что есть и где мы сейчас
                  // Если не равно то обнуляем все данные и запоминаем новый уид формы
                  this.$store.commit('formsaving/clearItems')
                  this.$store.commit('formsaving/saveUid', this.form['uid'])
                  return 0
              }

              // Есть ли уид формы в локал сторедж
              if (window.localStorage.getItem('formUid') !== null) {
                  // если он есть значит есть и остальные данные. Распаковываем в соотвествии с уид поля
                  for (let ifield in this.form.field) {
                    // проверяем есть ли значение по уид поля в форме
                    // если значениия нет в локал сторедж выводит подобный мусор, их не записываем
                    if (window.localStorage.getItem(this.form.field[Number(ifield)]['field']['uid']) !== null &&
                          window.localStorage.getItem(this.form.field[Number(ifield)]['field']['uid']) !== undefined &&
                          window.localStorage.getItem(this.form.field[Number(ifield)]['field']['uid']) !== 'null' &&
                          window.localStorage.getItem(this.form.field[Number(ifield)]['field']['uid']) !== '') {
                        // все что есть в норм виде записываем по уид в стор из локал сторедж
                        this.$store.commit("formsaving/addItem",
                            {uid: this.form.field[Number(ifield)]['field']['uid'],
                                    value: window.localStorage.getItem(this.form.field[Number(ifield)]['field']['uid'])})
                    }
                    else {
                        // если не норм записываем как есть для корректной работы.
                        // Иначе крашнется из за пропуска поля и несоблюдения порядка списка как идет в форме на страничке
                        this.$store.commit("formsaving/addItem",
                            {uid: this.form.field[Number(ifield)]['field']['uid'],
                                    value: this.form.field[Number(ifield)]['field']['defaultValue']})
                    }
                    // в любом случае очищаем поле из локал сторедж
                    window.localStorage.removeItem(this.form.field[Number(ifield)]['field']['uid'])
                  }
                  // очищаем упоминание уид формы из локал сторедж
                  window.localStorage.removeItem('formUid')
              }
              else if (this.$store.state.formsaving.items.length === 0) {
                // есть ли данные во вью стор. Нет - пропуск остального
                return 0
              }

              // Так, там что то есть. Проходимся по полям формы
              for (let ifield in this.form.field) {
                // есть ли значение во вью стор по номеру поля. Порядок идет сверху вниз, также как и сохрянем
                if (this.$store.state.formsaving.items[Number(ifield)] !== undefined) {
                  // совпадает ли уид поля формы с тем уид что мы сохранили во вью стор
                  if (this.form.field[Number(ifield)]['field']['uid'] === this.$store.state.formsaving.items[Number(ifield)]['uid'])
                  {
                    // передаем значение в форму из вью стор по номеру списка поля
                    this.form.field[Number(ifield)]['field']['defaultValue'] = this.$store.state.formsaving.items[Number(ifield)]['value']
                  }
                }
              }

              // поля ФИО плательщика и ФИО получателя услуг которые могут совпадать
              // нужно для проставления галки в форме
              // ищем два этих поля по метатайп
              let firstField = this.form.field.find(item => item.field.metaType == '2');
              if (firstField) {
                let secondField = this.form.field.find(item => item.field.metaType == '3');
                if (secondField) {
                  // не равняются ли один из них случайно нулу
                  if (firstField['field']['defaultValue'] !== null
                      && secondField['field']['defaultValue'] !== null) {
                      // если нет сохряем сопадают ли они, чтобы в случае совпадения проставить галку "Совпадают"
                      this.isNameSame = firstField['field']['defaultValue'] === secondField['field']['defaultValue'];
                  }
                }
              }
              return 1
            },
          /**
           * Сохранение формы при покидании страницы по кнопке назад
           */
            saveForm() {
                // нет ли случайно уже каких то данных во вью стор
                if (this.$store.state.formsaving.items.length !== 0) {
                    // там уже что то есть. Проходим по списку полей в форме
                    for (let ifield in this.form.field) {
                        // обновляем данные во вью стор в виду новых значений полей в форме
                        // передается как номер по списку так и значение, чтоб значть что обновлять
                        this.$store.commit("formsaving/updateItem", {num: Number(ifield), item:
                                                { uid: this.form.field[Number(ifield)]['field']['uid'],
                                                  value: this.form.field[Number(ifield)]['field']['defaultValue']
                                                }})
                    }
                    return 1
                }
                // там ничего не было. Сохрянем все данные полей в том порядке, в котором они идут в форме
                for (let ifield in this.form.field) {
                    this.$store.commit("formsaving/addItem",
                        {uid: this.form.field[Number(ifield)]['field']['uid'],
                                value: this.form.field[Number(ifield)]['field']['defaultValue']})
                }
                return 1
            },
          /**
           * Сохранение формы при покидании страницы по кнопке оплата
           */
            saveFormInStorage() {
                // метод выполяется при совершении транзакции оплаты. В этом случаее мы покидаем вью страницу,
                // и следовательно данные во вью стор сбрасываются. Выкручиваемся сохрняя данные в локал сторедж страницы
                // ключ - уид поля, значение - значение поля
                for (let ifield in this.form.field) {
                    window.localStorage.setItem(this.form.field[Number(ifield)]['field']['uid'], this.form.field[Number(ifield)]['field']['defaultValue'])
                }

                window.localStorage.setItem('formUid', this.form['uid'])
                return 1
            }
        },

        created() {
            this.loadForm()
        }
    }
</script>

<style scoped>

</style>
