<template>
    <div class="justify-center align-center d-flex">
        <v-card class="py-6" max-width="500">
            <v-row class="justify-center align-center">

                <v-card-text class="red--text text-h5">
                    <v-icon color="red" size="40">close</v-icon>&nbsp; Платеж не выполнен
                </v-card-text>
                <v-row class="text-left px-7">
                    <v-col>
                        <v-card-text class="subtitle-1">Идентификатор транзакции:</v-card-text>
                    </v-col>
                    <v-col>
                        <v-card-text class="subtitle-1">{{$route.params.form}}</v-card-text>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-btn :to="{ name:names.BASE}" class="primary" rounded>На главную</v-btn>

                    <v-btn :href="'mailto:pay@muctr.ru ?subject=Вопрос по транзакции: '
          +  $route.params.form + ' (pay.muct.ru)'" class="primary" rounded>Остались вопросы?
                    </v-btn>
                </v-card-actions>

            </v-row>

        </v-card>

    </div>
</template>

<script>
  import names from "@/modules/pay/routers/names";

  export default {
        name: "FailPay",
        data() {
            return {
                names: names
            }
        },
    }
</script>

<style scoped>

</style>
