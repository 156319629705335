const state = {
    items: [],
    formUid: undefined,
};

const mutations = {
    addItem(state, item) {
        state.items.push(item)
    },
    updateItem(state, obj) {
        state.items[obj.num] = obj.item
    },
    saveUid(state, uid) {
        state.formUid = uid
    },
    clearItems(state) {
        state.items = []
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    // actions,
}
