<template>
    <div class="page-content" style="margin: 0px; width: 100%">
        <SelectCategory></SelectCategory>
    </div>
</template>

<script>
    import SelectCategory from "@/modules/pay/page/public/SelectCategory";
    import names from '@/modules/pay/routers/names'
    import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
    import {isAuthenticated,clearLocalstorage} from "@/helper/auth";
    import store from "@/store";

    export default {
        name: "Base",
        components: {SelectCategory},
        data() {
            return {}
        },
        methods: {
          /**
           * Установка бокового меню
           */
            loadLeftmenu() {
                this.$store.commit('mainpage/changeTitle', 'Онлайн оплата услуг');
                // if (!store.getters['urls/getAllowed']('pay', 'public|pay|models|transaction-list', 'list'))
                //   clearLocalstorage()
                if (isAuthenticated()) {
                    this.$store.commit('leftmenu/replaceInstances', getInstMenu());
                    this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.BASE));
                    this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
                } else {
                    this.$store.commit('leftmenu/dissable');
                }
            },
          /**
           * Получение бокового меню
           */
            getLeftMenu() {
                let with_category_uid = (name) => {
                    return {name: name, params: {category: this.$route.params.category}}
                };
                let menu = []
                menu.push({
                    title: 'Перечень категорий',
                    icon: 'view_list',
                    included: true,
                    router: with_category_uid(names.BASE),
                })
                menu.push({
                    title: 'Мои транзакции',
                    icon: 'local_atm',
                    included: false,
                    router: names.PUBLIC.TRANZACTION,
                    name: names.PUBLIC.TRANZACTION,
                })
                // menu.push({
                //   title: 'Услуги печати',
                //   icon: 'print',
                //   included: false,
                //   router: names.PUBLIC.PRINT,
                //   name: names.PUBLIC.PRINT,
                // })
                return menu
            }
        },
        beforeRouteUpdate(to, from, next) {
            // this.loadLeftmenu()
            next()
        },
        created() {
            if (isAuthenticated() || this.$route.name === names.BASE) {
                this.$store.commit('leftmenu/enableLeftMenu');
                this.$store.commit('leftmenu/hide');
            }
            this.loadLeftmenu()
        }
    }
</script>

<style scoped>
    .border-right {
        border-right: 2px solid var(--steel-gray);
    }

</style>
