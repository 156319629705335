import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store/index'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '../public/css/main.css'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import DatetimePicker from 'vuetify-datetime-picker'
import VueCookie from 'vue-cookies';
import VueYandexMetrika from 'vue-yandex-metrika'
import {checkIsProductionServer,checkIsDevelopmentServer} from "../src/helper/index";
import * as Sentry from "@sentry/vue";

Vue.use(VueCookie);
Vue.use(DatetimePicker);

Sentry.init({
    Vue,
    dsn: "https://84dc5081746a2442116de30997d8d3fc@sentry.muctr.ru/11",
    integrations: [
        Sentry.browserTracingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

if(checkIsProductionServer())
    Vue.use(VueYandexMetrika, {
        id: process.env.VUE_APP_YandexMetrika,
        router: router,
        env: 'production',
        options:{
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
        }
    })

Vue.use(VueBreadcrumbs, {
    mixins: [
        {
            methods: {
                getRoute: function f(crumb) {
                    return {name: crumb.name, params: this.$route.params};
                }
            }
        }
    ],
    template: `
      <nav class="breadcrumb text-left" v-if="$breadcrumbs.length" xmlns="http://www.w3.org/1999/html">
      <template v-for="(crumb, i) in $breadcrumbs">
        <router-link class="breadcrumb-item" :to="getRoute(crumb)">
          <v-chip class="breadcrumb-item-chips mb-2" color="blue lighten-3 grey--text text--darken-1">
            {{ getBreadcrumb(crumb.meta.breadcrumb) }}
          </v-chip center>
        </router-link>
        <span v-if="$breadcrumbs.length - 1 !== i" class="pa-0 ma-0"><v-icon small class="pa-0 ma-0"
                                                                             style="vertical-align: baseline!important;">label_important</v-icon></span>
      </template>
      </nav>
    `
});

Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');

//HOOOOOOOOKKKK to xp chrome detected
function getChromeVersion() {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return raw ? parseInt(raw[2], 10) : false;
}

let chrome = getChromeVersion();
if (chrome && chrome <= 49) {
    window.onload = function () {
        let element = document.getElementById('base-content');
        element.style.paddingTop = '60px';
        element = document.getElementById("main-left-menu");
        element.style.marginTop = '60px';
        element.style.maxHeight = 'calc(100% - 60px)';
    }
}

