<template>
    <v-container style="height: 100%">
        <div class="pb-4">
            <v-expansion-panels>
                <v-expansion-panel
                >
                    <v-expansion-panel-header>
                        <div>
                            <v-icon>search</v-icon>
                            Поиск
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-0">
                        <v-text-field
                                dense
                                outlined
                                placeholder="Поиск"
                                v-model="search"
                        >
                        </v-text-field>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn @click="search=''">Сбросить фильтр</v-btn>
                        </v-card-actions>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <v-card flat>
            <v-card-title class="title style-color-main-gray">
                <span>Список форм</span>
                <v-spacer></v-spacer>
                <v-btn @click="opened_form = true" color="secondary" text>
                    <v-icon>add</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                @click="getItems()"
                                class="mr-2"
                                medium
                                v-on="on"
                        >
                            update
                        </v-icon>
                    </template>
                    <span>Обновить данные</span>
                </v-tooltip>
            </v-card-title>
            <v-data-table
                    :expanded.sync="expanded"
                    :footer-props="{'items-per-page-options':[100,250,500], 'items-per-page-text': 'Число элементов'}"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :search="search"
                    @click:row="setExpand"
                    class="elevation-1 text-left row-pointer"
                    no-data-text="Нет данных"
                    show-expand
                    single-expand
            >
                <template v-slot:item.category="{ item }">
                    <span v-if="item.category">{{item.category.name}}</span>
                </template>
                <template v-slot:item.taxID="{ item }">
                    {{ getDisplay(item.taxID, selectors.tax_id) }}
                </template>
                <template v-slot:item.payAttribute="{ item }">
                    {{ getDisplay(item.payAttribute, selectors.attribute) }}
                </template>
                <template v-slot:item.published="{ item }">
                    <v-icon v-if="item.published">done</v-icon>
                    <v-icon v-else>clear</v-icon>
                </template>
                <template v-slot:item.obj="{ item }">
                    <v-row>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                        @click="updateItem(item)"
                                        class="mr-2"
                                        medium
                                        v-on="on"
                                >
                                    edit
                                </v-icon>
                            </template>
                            <span>Редактировать</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                        @click="openConfirmDelete(item)"
                                        medium
                                        v-on="on"
                                >
                                    delete
                                </v-icon>
                            </template>
                            <span>Удалить</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                        @click="openCopy(item)"
                                        class="mr-2"
                                        medium
                                        v-on="on"
                                >
                                    content_copy
                                </v-icon>
                            </template>
                            <span>Скопировать форму</span>
                        </v-tooltip>
                    </v-row>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-row>
                            <v-col>
                                <ListExFF :urlData="item.field.get_params"></ListExFF>
                            </v-col>
                            <v-col>
                                <ListExFD :urlData="item.document.get_params"></ListExFD>
                            </v-col>
                        </v-row>
                    </td>
                </template>
                <template v-slot:no-data>
                    <v-card-text>Данные не загружены</v-card-text>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog @input="closedForm" persistent v-model="opened_form">
            <Form @addItem="addItem"
                  @ended="closedForm"
                  @refreshItem="refreshItem"
                  v-bind="selected"
                  v-bind:info="$route.params.id"
                  v-if="opened_form"
            >
            </Form>
        </v-dialog>

        <v-dialog persistent v-model="opened_confirm" width="500">
            <v-card v-if="deletable">
                <v-card-title>Подтвердите удаление формы</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Вы уверены что хотите удалить {{deletable.name}} ?<br>
                </v-card-text>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="deleteItem(deletable.id)"
                                color="warning"
                                text
                        >
                            Удалить
                        </v-btn>
                        <v-btn
                                @click="closeConfirmDelete"
                                text
                        >Отмена
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="opened_copy" width="500">
            <v-card v-if="copy">
                <v-card-title>Подтвердите копирование</v-card-title>
                <v-divider></v-divider>
                <v-text-field class="pa-5"
                              label="Новое имя формы"
                              v-model="copy.name"
                ></v-text-field>
                <v-alert type="error" v-if="hasError('non_field_errors')">
                    {{getError('non_field_errors')[0]}}
                </v-alert>
                <v-alert type="error" v-if="hasError('form')">
                    {{getError('form')[0]}}
                </v-alert>
                <v-alert type="error" v-if="hasError('name')">
                    {{getError('name')[0]}}
                </v-alert>
                <v-card-text>
                    Вы уверены что хотите скопировать?<br>
                </v-card-text>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="cloneItem(copy)"
                                color="warning"
                                text
                        >
                            Копирование
                        </v-btn>
                        <v-btn
                                @click="closeCopy"
                                text
                        >Отмена
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>


<script>
    import UserDataMixin from '@/mixins/UserDataMixin'
    import UserDataFormMixin from "@/mixins/UserDataFormMixin"
    import {makeGetRequest, makePostRequest} from "@/helper/requests";
    import urls from '@/urls/helper.js'
    import Form from "./Form"
    import ListExFF from "@/modules/pay/page/admin/admin_pay/form/expanded/ListFF.vue"
    import ListExFD from "@/modules/pay/page/admin/admin_pay/form/expanded/ListFD.vue"

    export default {
        name: "List",
        mixins: [UserDataMixin, UserDataFormMixin],
        components: {
            Form,
            ListExFF,
            ListExFD
        },
        data() {
            return {
                URL_LIST: urls.getPayUrlWithParameters('admin|pay|models|form-list', 'list'),
                URL_DELETE: urls.getPayUrlWithParameters('admin|pay|models|form-detail', 'destroy'),
                items: [],
                expanded: [],
                singleExpand: false,
                opened_copy: false,
                search: '',
                copy: {},
                loading: true,
                selectors: {
                    tax_id: [],
                    attribute: [],
                },
                headers: [
                    {text: 'Наименование', sortable: false, align: 'left', value: "name"},
                    {text: 'Опубликована', sortable: false, align: 'left', value: "published"},
                    {text: 'Формула расчета', sortable: false, align: 'left', value: "formula"},
                    {text: 'Категория', sortable: false, align: 'left', value: "category"},
                    {text: 'Код налога', sortable: false, align: 'left', value: "taxID"},
                    {text: 'Признак способа расчёта', sortable: false, align: 'left', value: "payAttribute"},
                    {text: 'Сортировка', sortable: false, align: 'left', value: "sort"},
                    {text: 'Действия', sortable: false, align: 'right', value: "obj",},
                    {text: '', value: 'data-table-expand'},
                ]
            }
        },

        created() {
            let url = urls.getPayUrlWithParameters('admin|pay|choices|form_pay_attribute_choices', 'get')
            this.loadSelector("attribute", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|form_tax_id_choices', 'get')
            this.loadSelector("tax_id", url)
            this.getItems();
        },
        methods: {
            setExpand(item) {
                this.expanded = item === this.expanded[0] ? [] : [item]
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    this.selectors[data].push({text: 'Не указано', value: null})
                })
            },
            openCopy: function (obj) {
                this.copy = Object.assign({}, obj);
                this.opened_copy = true;
            },

            closeCopy: function () {
                this.opened_copy = false;
                this.errors = {}
                setTimeout(() => {
                    this.copy = null;
                }, 500);

            },
            cloneItem() {
                let data = {form: this.copy.id, name: this.copy.name}
                let access = true
                let url = urls.getPayUrlWithParameters('admin|pay|actions|clone_form', 'post')
                makePostRequest(url, data).then(resp => {
                    if (!resp.ok) {
                        access = false
                    }
                    return resp.json()
                }).then(json => {
                    if (access) {
                        this.opened_copy = false;
                        this.getItems();
                        setTimeout(() => {
                            this.copy = null;
                        }, 500);
                    } else {
                        this.receiveErrors(json);
                    }
                })
            },
            addItem() {
                this.closedForm()
                this.getItems();
            },
            refreshItem() {
                this.closedForm()
                this.getItems();
            },
            getItems() {
                this.loading = true
                let sys = true
                // console.log(this.URL_LIST)
                makeGetRequest(this.URL_LIST).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.items = json
                    this.loading = false
                })
            },
        }
    }


</script>
<style scoped>
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }
</style>
