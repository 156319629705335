<template>
    <v-container>
        <v-card>
            <v-card-title class="title style-color-main-gray">
                <span>Список полей документов форм</span>
                <v-spacer></v-spacer>
                <v-btn @click="opened_form = true" color="secondary" text>
                    <v-icon>add</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                @click="getItems()"
                                class="mr-2"
                                medium
                                v-on="on"
                        >
                            update
                        </v-icon>
                    </template>
                    <span>Обновить данные</span>
                </v-tooltip>
            </v-card-title>
            <v-data-table
                    :footer-props="{'items-per-page-options':[100,250,500], 'items-per-page-text': 'Число элементов'}"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    class="elevation-1"
                    no-data-text="Нет данных"
            >
                <template v-slot:item.form="{ item }">
                    {{ getDisplay(item.form, selectors.form) }}
                </template>
                <template v-slot:item.document="{ item }">
                    {{ getDisplay(item.document, selectors.document) }}
                </template>
                <template v-slot:item.btn="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="updateItem(item)"
                                    class="mr-2"
                                    medium
                                    v-on="on"
                            >
                                edit
                            </v-icon>
                        </template>
                        <span>Редактировать</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="openConfirmDelete(item)"
                                    medium
                                    v-on="on"
                            >
                                delete
                            </v-icon>
                        </template>
                        <span>Удалить</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <v-card-text>Данные не загружены</v-card-text>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog @input="closedForm" persistent v-model="opened_form" width="500">
            <Form @addItem="addItem"
                  @ended="closedForm"
                  @refreshItem="refreshItem"
                  v-bind="selected"
                  v-bind:info="$route.params.id"
                  v-if="opened_form"
            >
            </Form>
        </v-dialog>

        <v-dialog persistent v-model="opened_confirm" width="500">
            <v-card v-if="deletable">
                <v-card-title>Подтвердите удаление</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Вы уверены что хотите удалить?<br>
                </v-card-text>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="deleteItem(deletable.id)"
                                color="warning"
                                text
                        >
                            Удалить
                        </v-btn>
                        <v-btn
                                @click="closeConfirmDelete"
                                text
                        >Отмена
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
    import UserDataMixin from '@/mixins/UserDataMixin'
    import {makeGetRequest} from "@/helper/requests";
    import urls from '@/urls/helper.js'
    import Form from "./Form"

    export default {
        name: "List",
        mixins: [UserDataMixin],
        components: {Form},
        data() {
            return {
                URL_LIST: urls.getPayUrlWithParameters('admin|pay|models|form_document-list', 'list'),
                URL_DELETE: urls.getPayUrlWithParameters('admin|pay|models|form_document-detail', 'destroy'),
                items: [],
                selectors: {
                    form: [],
                    document: [],
                },
                loading: true,
                headers: [
                    {text: 'Форма', sortable: false, align: 'left', value: 'form',},
                    {text: 'Документ', sortable: false, align: 'left', value: 'document',},
                    {text: 'Сортировка', sortable: false, align: 'left', value: 'sort',},
                    {text: 'Действия', sortable: false, align: 'right', value: 'btn',},
                ]
            }
        },

        created() {
            // this.$store.commit('mainpage/changeTitle', 'Список форм документов');
            this.getItems();
            let url = urls.getPayUrlWithParameters('admin|core|models|document-list', 'list')
            this.loadSelector("document", url)
            let url2 = urls.getPayUrlWithParameters('admin|pay|models|form-list', 'list')
            this.loadSelector("form", url2)
        },
        methods: {
            getDisplay: function (value, selector) {
                if (selector) {
                    let res = selector.find((el) => {
                        if (el.id === value)
                            return true
                    });
                    if (res) {
                        return res.name;
                    } else
                        return null;
                } else
                    return null;
            },
            addItem() {
                this.closedForm()
                this.getItems();
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    this.selectors[data].push({name: 'Не указано', id: null})
                })
            },
            getItems() {
                this.loading = true
                let sys = true
                // console.log(this.URL_LIST)
                makeGetRequest(this.URL_LIST).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.items = json
                    this.loading = false
                })
            },
        }
    }


</script>
<style scoped>

</style>
