import names from '@/modules/pay/routers/names'
import {ACCOUNTS_INSTANCE, PAY_INSTANCE} from "@/helper/consts";
import Category from "@/modules/pay/page/public/Category.vue"
import PayForm from "@/modules/pay/page/public/PayForm.vue"
import FailPay from "@/modules/pay/page/public/FailPay.vue"
import SuccessPay from "@/modules/pay/page/public/SuccessPay.vue"

export default ([
    {
        path: '/fail/:form([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})?/',
        name: names.PUBLIC.FAIL,
        component: FailPay,
        meta: {
            breadcrumb: 'Ошибка',
            requiredAuth: false,
            instances: [
                PAY_INSTANCE,
                ACCOUNTS_INSTANCE
            ],
        },
    },
    {
        path: '/success/:form([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})?/',
        name: names.PUBLIC.SUCCESS,
        component: SuccessPay,
        meta: {
            breadcrumb: 'Успех',
            requiredAuth: false,
            instances: [
                PAY_INSTANCE,
                ACCOUNTS_INSTANCE
            ],
        },
    },
    {
        path: '/category/:category([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})?/',
        name: names.PUBLIC.CATEGORY,
        component: Category,
        meta: {
            breadcrumb: 'Категория',
            requiredAuth: false,
            instances: [
                PAY_INSTANCE,
                ACCOUNTS_INSTANCE
            ],
        },
    },
    {
        path: '/form/:form([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})?/',
        name: names.PUBLIC.FORM,
        component: PayForm,
        meta: {
            breadcrumb: 'Услуга',
            requiredAuth: false,
            instances: [
                PAY_INSTANCE,
                ACCOUNTS_INSTANCE
            ],
        },
    }

])
