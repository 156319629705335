<template>
    <div>
        <v-layout justify-center>
            <v-flex>
                <v-card ref="form">
                    <v-toolbar color="primary" dark style="position: sticky; z-index: 10000;top: 0">
                        <v-toolbar-title>
                            <span v-if="!!id">Изменить документ формы</span>
                            <span v-else> <div>Добавить документ формы</div></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-toolbar-items>
                                <v-btn @click="closeDialog" class="pa-0 ma-0" dark icon style="left: +16px;">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-autocomplete
                                :error="hasError('document')"
                                :error-messages="getError('document')"
                                :items="selectors.document"
                                item-text="name"
                                item-value="id"
                                label="Документ"
                                v-model="value.document">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.name}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-autocomplete
                                :disabled="disable"
                                :error="hasError('form')"
                                :error-messages="getError('form')"
                                :items="selectors.form"
                                item-text="name"
                                item-value="id"
                                label="Форма"
                                v-model="value.form">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.name}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                                :error="hasError('sort')"
                                :error-messages="getError('sort')"
                                label="Сортировка"
                                type="number"
                                v-model.number="value.sort"
                        ></v-text-field>
                        <v-alert type="error" v-if="hasError('non_field_errors')">
                            {{getError('non_field_errors')}}
                        </v-alert>
                        <v-alert type="error" v-if="hasError('detail')">
                            {{getError('detail')}}
                        </v-alert>
                        <v-btn @click="apply" color="primary">Сохранить</v-btn>
                    </v-card-text>

                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

    import UserDataFormMixin from '@/mixins/UserDataFormMixin'
    import {makeGetRequest} from "@/helper/requests";
    import urls from '@/urls/helper.js'

    export default {
        name: "Courses",
        mixins: [UserDataFormMixin],
        props: {
            id: Number,
            sort: Number,
            document: Number,
            form: Number,
            disable: Boolean
        },
        data() {
            return {
                URL_CREATE: urls.getPayUrlWithParameters('admin|pay|models|form_document-list', 'create'),
                URL_UPDATE: urls.getPayUrlWithParameters('admin|pay|models|form_document-detail', 'update'),
                selectors: {
                    form: [],
                    document: [],
                },
                value: {
                    id: this.id || undefined,
                    document: this.document || null,
                    sort: this.sort || 0,
                    form: this.form || null,
                },
            }
        },
        methods: {
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    this.selectors[data].push({name: 'Не указано', id: null})

                })
            },
            closeDialog() {
                this.$emit('ended', '')
            },
        },
        created() {
            let url = urls.getPayUrlWithParameters('admin|core|models|document-list', 'list')
            this.loadSelector("document", url)
            let url2 = urls.getPayUrlWithParameters('admin|pay|models|form-list', 'list')
            this.loadSelector("form", url2)
        }
    }

</script>

<style scoped>

</style>
