<template>
    <v-container>
        <div class="pb-4">
            <v-expansion-panels>
                <v-expansion-panel
                >
                    <v-expansion-panel-header>
                        <div>
                            <v-icon>search</v-icon>
                            Поиск
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-0">
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-on:keyup.enter="searchData"
                                outlined
                                clearable
                                label="Поиск: идентификатор транзакции, номер фискального документа, ФИО, Email"
                                v-model="search.value"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-on:keyup.enter="searchData"
                                v-model.number="totalCost" type="number"
                                clearable
                                outlined step="1" label="Поиск: итоговая стоимость">
                            </v-text-field>
                          </v-col>
                            <v-col cols="6">
                                <v-select
                                        label="Проверка"
                                        :items="selectorsBool"
                                        v-model="bankCheck"
                                >
                                </v-select>
                                <v-select
                                        label="Регистрация"
                                        :items="selectorsBool"
                                        v-model="bankRegister"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                        label="Чек отправлен"
                                        :items="selectorsBool"
                                        v-model="chekSent"
                                >
                                </v-select>
                                <v-select
                                        label="Выгружено"
                                        :items="selectorsBool"
                                        v-model="bookkeepingSent"
                                >
                                </v-select>
                            </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-select
                                label="Тип оплаты"
                                :items="selectors.type"
                                v-model="type"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-select
                                label="Признак способа расчёта"
                                :items="selectors.payAttribute"
                                v-model="formData__payAttribute"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="startDateTime__gte" type="datetime-local"
                               label="Дата инициализации (начало)" clearable>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="startDateTime__lte" type="datetime-local"
                                 label="Дата инициализации (окончание)" clearable>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="bankCheckDateTime__gte" type="datetime-local"
                                label="Дата проверки (начало)" clearable>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="bankCheckDateTime__lte" type="datetime-local"
                                label="Дата проверки (окончание)" clearable>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="bankRegisterDateTime__gte" type="datetime-local"
                                label="Дата регистрации (начало)" clearable>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="bankRegisterDateTime__lte" type="datetime-local"
                                label="Дата регистрации (окончание)" clearable>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="chekSentDateTime__gte" type="datetime-local"
                                label="Дата отправки чека (начало)" clearable>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="chekSentDateTime__lte" type="datetime-local"
                                label="Дата отправки чека (окончание)" clearable>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="bookkeepingSentDateTime__gte" type="datetime-local"
                                label="Дата выгрузки  (начало)" clearable>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field
                                v-model="bookkeepingSentDateTime__lte" type="datetime-local"
                                label="Дата выгрузки (окончание)" clearable>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn @click="searchData">Найти</v-btn>
                            <v-btn @click="clearFilter()">Сбросить фильтр</v-btn>
                        </v-card-actions>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <v-card>
            <v-card-title class="title style-color-main-gray">
                <span class="mr-3">Транзакции</span>
                <v-row v-if="allowedData()">
                  <v-btn
                      class="ml-2"
                      elevation="2"
                      @click="openSendForce(selected)"
                      :disabled="selected.length === 0"
                  >
                    <v-icon class="mr-2"> settings_applications </v-icon>
                    <span  v-if="$vuetify.breakpoint.mdAndUp">Принудительные действия</span>
                  </v-btn>
                  <v-btn
                      class="ml-2"
                      elevation="2"
                      @click="openSendChek(selected)"
                      :disabled="selected.length === 0"
                  >
                    <v-icon class="mr-2"> send </v-icon>
                    <span v-if="$vuetify.breakpoint.mdAndUp">Повторно отправить чек</span>
                  </v-btn>
                  <v-btn
                      class="ml-2"
                      elevation="2"
                      @click="openSendBooker(selected)"
                      :disabled="selected.length === 0"
                  >
                    <v-icon class="mr-2"> request_quote </v-icon>
                    <span v-if="$vuetify.breakpoint.mdAndUp">Повторно отправить в бухгалтерию</span>
                  </v-btn>
                </v-row>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                @click="searchData()"
                                class="mr-2"
                                medium
                                v-on="on"
                        >
                            update
                        </v-icon>
                    </template>
                    <span>Обновить данные</span>
                </v-tooltip>
            </v-card-title>
            <v-data-table
                    :expanded.sync="expanded"
                    :footer-props="{'items-per-page-options':[10, 100, 250, 500], 'items-per-page-text': 'Число элементов'}"
                    :headers="headers"
                    :items="items"
                    :items-per-page.sync="pagination.size"
                    :loading="pagination.loading"
                    :page.sync="pagination.page"
                    :server-items-length="pagination.count"
                    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                    class="text-left row-pointer"
                    item-key="uid"
                    no-data-text="Данные отсутствуют"
                    no-results-text="Ничего не было найдено"
                    show-expand
                    v-model="selected"
                    show-select
            >
                <template v-slot:item.bankCheck="{  item }">

                        <v-icon v-if="item.bankCheck">done</v-icon>
                        <v-icon v-else>clear</v-icon>

                </template>

                <template v-slot:item.bankRegister="{  item }">

                        <v-icon v-if="item.bankRegister">done</v-icon>
                        <v-icon v-else>clear</v-icon>

                </template>
                <template v-slot:item.chekSent="{  item }">

                        <v-icon v-if="item.chekSent">done</v-icon>
                        <v-icon v-else>clear</v-icon>

                </template>
                <template v-slot:item.name="{  item }">

                        {{item.formData.name}}

                </template>
              <template v-slot:item.type="{  item }">

                  {{getDisplay(item.type,selectors.type)}}

              </template>
                <template v-slot:item.startDateTime="{  item }">
                    <td  v-if="item.startDateTime">
                        {{ displayDate(item.startDateTime,true)}}
                    </td>
                </template>
                <template v-slot:item.bankCheckDateTime="{  item }">
                    <td   v-if="item.bankCheckDateTime">
                        {{ displayDate(item.bankCheckDateTime,true)}}
                    </td>
                </template>
                <template v-slot:item.bankRegisterDateTime="{  item }">
                    <td   v-if="item.bankRegisterDateTime">
                        {{ displayDate(item.bankRegisterDateTime,true)}}
                    </td>
                </template>
                <template v-slot:item.chekSentDateTime="{  item }">
                    <td   v-if="item.chekSentDateTime">
                        {{ displayDate(item.chekSentDateTime,true)}}
                    </td>
                </template>
                <template v-slot:item.bookkeepingSent="{  item }">
                    <td  >
                        <v-icon v-if="item.bookkeepingSent">done</v-icon>
                        <v-icon v-else>clear</v-icon>
                    </td>
                </template>
                <template v-slot:item.bookkeepingSentDateTime="{  item }">
                    <td   v-if="item.bookkeepingSentDateTime">
                        {{ displayDate(item.bookkeepingSentDateTime,true)}}
                    </td>
                </template>
              <template v-slot:item.printServerSent="{  item }">
                <v-icon v-if="item.printServerSent">done</v-icon>
                <v-icon v-else>clear</v-icon>
              </template>
              <template v-slot:item.printServerSentDateTime="{  item }">
                <td   v-if="item.printServerSentDateTime">
                  {{ displayDate(item.printServerSentDateTime,true)}}
                </td>
              </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <TransactionDetail :form="item" :selectors="selectors"></TransactionDetail>
                    </td>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog persistent v-model="sendChekDialod" width="500">
            <v-card v-if="send">
                <v-card-title>Подтвердите повторную отправку чека</v-card-title>
                <v-divider></v-divider>
              <v-select
                  class="px-3"
                  label="Признак способа расчёта"
                  :items="selectors.payAttribute"
                  v-model="forcePayAttribute"
              >
              </v-select>
              <v-select
                  class="px-3"
                  label="Тип чека"
                  :items="selectors.document_type_choices"
                  v-model="documentType"
              >
              </v-select>
              <v-select
                  class="px-3"
                  label="Полная сумма оплаты"
                  :items="selectorsBool"
                  v-model="nonCache"
              >
              </v-select>
              <v-select
                  class="px-3"
                  label="Полная сумма оплаты предоплатой (зачётом аванса)"
                  :items="selectorsBool"
                  v-model="advancePayment"
              >
              </v-select>
                <v-alert type="error" v-if="hasError('non_field_errors')">
                    {{getError('non_field_errors')}}
                </v-alert>
                <v-alert type="error" v-if="hasError('detail')">
                    {{getError('detail')}}
                </v-alert>
                <v-alert type="error" v-if="hasError('transaction')">
                    {{getErrorArray('transaction')}}
                </v-alert>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="sendChek(send)"
                                color="warning"
                                text
                        >
                            Отправить
                        </v-btn>
                        <v-btn
                                @click="closeSend"
                                text
                        >Отмена
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>

      <v-dialog persistent v-model="sendBookerDialod" width="500">
            <v-card v-if="send">
                <v-card-title>Подтвердите повторную отправку</v-card-title>
                <v-card-title> в бухгалтерию</v-card-title>
                <v-divider></v-divider>
                <v-alert type="error" v-if="hasError('non_field_errors')">
                    {{getError('non_field_errors')}}
                </v-alert>
                <v-alert type="error" v-if="hasError('detail')">
                    {{getError('detail')}}
                </v-alert>
                <v-alert type="error" v-if="hasError('transaction')">
                    {{getErrorArray('transaction')}}
                </v-alert>
                <v-card-actions>
                    <v-flex>
                        <v-btn
                                @click="sendBooker(send)"
                                color="warning"
                                text
                        >
                            Отправить
                        </v-btn>
                        <v-btn
                                @click="closeSend"
                                text
                        >Отмена
                        </v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-dialog>
      <v-dialog persistent v-model="sendForceDialod" width="500">
        <v-card v-if="send">
          <v-card-title>Принудительные действия:</v-card-title>

          <v-alert type="error" v-if="hasError('non_field_errors')">
            {{getError('non_field_errors')}}
          </v-alert>
          <v-alert type="error" v-if="hasError('detail')">
            {{getError('detail')}}
          </v-alert>
          <v-alert type="error" v-if="hasError('transaction')">
            {{getErrorArray('transaction')}}
          </v-alert>
          <v-card-text>
            <v-btn
                class="my-0"
                block
                outlined
                @click="SendForceCheckBank(send)"
                color="error"
            >
              Проверка в банке
            </v-btn>

            <v-btn
                block
                outlined
                class="my-3"
                @click="forceRegisterBank(send)"
                color="error"
            >
              Регистрация в банке
            </v-btn>
            <v-btn
                block
                outlined
                class="my-0"
                @click="forceRegisterPrint(send)"
                color="error"
            >
              Регистрация в Сер Печ
            </v-btn>
          </v-card-text>

          <v-card-actions>

             <v-spacer></v-spacer>
              <v-btn
                  @click="closeSend"
                  text
              >Отмена
              </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
</template>

<script>

    import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
    import UserDataMixin from '@/mixins/UserDataMixin'
    import UserDataFormMixin from "@/mixins/UserDataFormMixin"
    import urls from '@/urls/helper.js'
    import {loadData, makeGetRequest, makePostRequest} from "@/helper/requests";
    import TransactionDetail from "@/modules/pay/components/TransactionDetail"
    import {generateListUidToName} from "@/helper/uidToName";
    import moment from "moment";
    import OneFieldBaseSearchMixin from "@/mixins/OneFieldBaseSearchMixin";
    import store from "@/store";

    export default {
        name: "List",
        mixins: [
            PaginatedDataMapperMixin,
            UserDataMixin,
            OneFieldBaseSearchMixin,
            UserDataFormMixin
        ],
        components: {
            TransactionDetail
        },
        props: {},
        data() {
            return {
                search: {
                    parameter: 'search'
                },
                selectors: {
                    type: [],
                    taxID: [],
                    payAttribute: [],
                    document_type_choices: [],
                    chekonline_error: [],
                },
                expanded: [],
                items: [],
                sendChekDialod: false,
                sendBookerDialod: false,
                sendForceDialod: false,
                send: null,
                documentType: null,
                forcePayAttribute: null,
                pagination: {},
                URL_LIST: urls.getPayUrlWithParameters('admin|pay|models|transaction-list', 'list'),
                URL_DELETE: urls.getPayUrlWithParameters('admin|pay|models|transaction-detail', 'destroy'),
                selectorsBool:[
                    {text:'Не указано',value:null},
                    {text:'Да',value:true},
                    {text:'Нет',value:false}
                    ],
                bookkeepingSent:null,
                bankCheck:null,
                bankRegister:null,
                chekSent:null,
                nonCache:null,
                advancePayment:null,
                totalCost:null,
                type:null,
                startDateTime__gte: null,
                startDateTime__lte: null,
                bankCheckDateTime__gte: null,
                bankCheckDateTime__lte: null,
                bankRegisterDateTime__gte: null,
                bankRegisterDateTime__lte:null,
                chekSentDateTime__gte: null,
                chekSentDateTime__lte:null,
                bookkeepingSentDateTime__gte: null,
                bookkeepingSentDateTime__lte: null,
                formData__payAttribute: null,
                selected: [],
                headers: [
                    {text: 'Индефикатор транзакции', sortable: false, align: 'left', value: "uid"},
                    {text: 'Тип оплаты', sortable: false, align: 'left', value: "type"},
                    {text: 'Сумма', sortable: false, align: 'left', value: "totalCost"},
                    {text: 'Дата инициализации', sortable: false, align: 'left', value: "startDateTime"},
                    {text: 'Проверка', sortable: false, align: 'left', value: "bankCheck"},
                    {text: 'Дата проверки', sortable: false, align: 'left', value: "bankCheckDateTime"},
                    {text: 'Регистрация', sortable: false, align: 'left', value: "bankRegister"},
                    {text: 'Дата регистрации', sortable: false, align: 'left', value: "bankRegisterDateTime"},
                    {text: 'Чек отправлен', sortable: false, align: 'left', value: "chekSent"},
                    {text: 'Дата отправки чека', sortable: false, align: 'left', value: "chekSentDateTime"},
                    {text: 'Выгружено', sortable: false, align: 'left', value: "bookkeepingSent"},
                    {text: 'Дата выгрузки', sortable: false, align: 'left', value: "bookkeepingSentDateTime"},
                    {text: 'Сервис печати', sortable: false, align: 'left', value: "printServerSent"},
                    {text: 'Дата сервис печати', sortable: false, align: 'left', value: "printServerSentDateTime"},
                    {text: '', value: 'data-table-expand'},
                ]
            }
        },

        methods: {
            clearFilter(){
                this.search.value='';
                this.bookkeepingSent = null;
                this.bankCheck = null;
                this.bankRegister = null;
                this.chekSent = null;
                this.totalCost = null;
                this.startDateTime__gte= null;
                this.startDateTime__lte= null;
                this.bankCheckDateTime__gte= null;
                this.bankCheckDateTime__lte= null;
                this.bankRegisterDateTime__gte= null;
                this.bankRegisterDateTime__lte= null;
                this.chekSentDateTime__gte= null;
                this.chekSentDateTime__lte= null;
                this.bookkeepingSentDateTime__gte= null;
                this.bookkeepingSentDateTime__lte= null;
                this.formData__payAttribute= null;
                this.getItems()
            },
            openSendForce(items) {
              this.send = items
              this.sendForceDialod = true
            },
            openSendBooker(items) {
                this.send = items
                this.sendBookerDialod = true
            },
            allowedData() {
                return store.getters['urls/getAllowed']('pay', 'admin|pay|actions|send_chek', 'post')
            },
            getDisplay: function (value, selector) {
              if (selector) {
                let res = selector.find((el) => {
                  if (el.value === value)
                    return true
                });
                if (res)
                  return res.text;
                else
                  return null;
              } else
                return null;
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    this.selectors[data].push({text:'Не выбрано',value:null})
                })
            },
            openSendChek(items) {
                this.send = items
                this.sendChekDialod = true
            },
            sendForce(url,send){
              let mas_uid = []
              for (let i in send) {
                mas_uid.push(send[i].uid)
              }

              let data = {transactions: mas_uid}
              let access = true
              makePostRequest(url, data).then(resp => {
                if (!resp.ok) {
                  access = false
                }
                return resp.json()
              }).then(json => {
                if (access) {
                  this.send = []
                  this.sendForceDialod = false
                  this.getItems();
                } else {
                  this.receiveErrors(json);
                }
              })
            },
            forceRegisterPrint(send){
              let url = urls.getPayUrlWithParameters('admin|pay|actions|send_print_server', 'post')
              this.sendForce(url,send)
            },
            forceRegisterBank(send) {
              let url = urls.getPayUrlWithParameters('admin|pay|actions|force_register_bank', 'post')
              this.sendForce(url,send)
              },
            sendChek(send) {
              let mas_uid = []
              for (let i in send) {
                mas_uid.push(send[i].uid)
              }

              let data = {transactions: mas_uid}
              if(this.forcePayAttribute)
                data.forcePayAttribute = this.forcePayAttribute
              if(this.documentType)
                data.documentType = this.documentType
              if(this.nonCache !== null)
                data.nonCache = this.nonCache
              if(this.advancePayment !== null)
                data.advancePayment = this.advancePayment
              let access = true
              let url = urls.getPayUrlWithParameters('admin|pay|actions|send_chek', 'post')
              makePostRequest(url, data).then(resp => {
                if (!resp.ok) {
                  access = false
                }
                return resp.json()
              }).then(json => {
                if (access) {
                  this.send = []
                  this.sendChekDialod = false
                  this.forcePayAttribute = null
                  this.send_chek = null
                  // this.getItems();
                } else {
                  this.receiveErrors(json);
                }
              })
            },
            SendForceCheckBank(send) {
              let url = urls.getPayUrlWithParameters('admin|pay|actions|force_check_bank', 'post')
              this.sendForce(url,send)
            },
            sendBooker(send) {
                let mas_uid = []
                for (let i in send) {
                  mas_uid.push(send[i].uid)
                }

                let data = {transactions: mas_uid}
                let access = true
                let url = urls.getPayUrlWithParameters('admin|pay|actions|send_bookkeeping', 'post')
                makePostRequest(url, data).then(resp => {
                    if (!resp.ok) {
                        access = false
                    }
                    return resp.json()
                }).then(json => {
                    if (access) {
                        this.send = []
                        this.sendForceDialod = false
                        this.getItems();
                    } else {
                        this.receiveErrors(json);
                    }
                })
            },
            closeSend() {
                this.send = null
                this.sendChekDialod = false
                this.sendForceDialod = false
                this.sendBookerDialod = false
                this.errors = []
            },
            displayDate(date, minutes = false) {
                let format = 'DD.MM.YYYY';
                if (minutes)
                    format += ' HH:mm:ss';
                return moment(date).format(format)
            },
            getItems() {
                this.closeSend()
                this.load();
            },
            addItem() {
                this.closeSend()
                this.load();
            },
            refreshItem() {
                this.closeSend()
                this.load();
            },

            loadData(url = undefined) {
                this.load(url);
            },
            searchData: function () {
                this.cleanPaginationPageData();
                let oldUrl = this.getCurrentLoadUrl()
                this.setCurrentLoadUrl(
                    this.addSearchParameter(
                        this.getCurrentLoadUrl()
                    )
                )
              let searcharr = ["bookkeepingSent","bankCheck"
                ,"bankRegister","chekSent","totalCost","startDateTime__gte",
                "type","startDateTime__lte","bankCheckDateTime__gte","bankCheckDateTime__lte",
                "bankRegisterDateTime__gte","bankRegisterDateTime__lte","chekSentDateTime__gte",
                "chekSentDateTime__lte","bookkeepingSentDateTime__gte","bookkeepingSentDateTime__lte",
                "formData__payAttribute"]

                for (var i = 0; i < searcharr.length; i++) {
                    this.setCurrentLoadUrl(
                        this.addGetParameter(
                            this.getCurrentLoadUrl(),
                            searcharr[i],
                            this[searcharr[i]]
                        )
                    )
                }

              if(oldUrl === this.getCurrentLoadUrl())
                this.load(this.getCurrentLoadUrl())
            },
            load: function (url = undefined) {
                this.setLoading();
                if (url === undefined){
                    url = this.URL_LIST
                }
                loadData(
                    url,
                    (data) => {
                        this.mapPagination(data);
                        this.mapResults(data, 'items');
                        this.setCurrentLoadUrl(url);
                        for (let i in this.items) {
                            this.$set(this.items[i], "fio", "-")
                        }
                        generateListUidToName(this.items, "user", "fio", () => {
                            this.setNotLoading();
                        })
                    }
                )
            },

        },
        created() {
            let url = urls.getPayUrlWithParameters('admin|pay|choices|form_pay_attribute_choices', 'get')
            this.loadSelector("payAttribute", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|form_tax_id_choices', 'get')
            this.loadSelector("taxID", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|chekonline_error_code_choices', 'get')
            this.loadSelector("chekonline_error", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|transaction_type_choices', 'get')
            this.loadSelector("type", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|document_type_choices', 'get')
            this.loadSelector("document_type_choices", url)
            this.load();
        }
    }
</script>

<style scoped>
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }
</style>
