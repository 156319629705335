<template>
    <div>
        <v-layout justify-center>
            <v-flex>
                <v-card ref="form">
                    <v-toolbar color="primary" dark style="position: sticky; z-index: 10000;top: 0">
                        <v-toolbar-title>
                            <span v-if="!!id">Изменить форму</span>
                            <span v-else> <div>Добавить форму</div></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeDialog" class="pa-0 ma-0 " dark icon style="left: +16px;">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                                :error="hasError('name')"
                                :error-messages="getError('name')"
                                label="Наименование"
                                v-model="value.name">
                            <template slot="label">Наименование<span class="red--text">*</span>:</template>
                        </v-text-field>
                        <v-text-field :error="hasError('description')"
                                      :error-messages="getError('description')"
                                      label="Описание"
                                      v-model="value.description"
                        ></v-text-field>
                        <v-autocomplete
                                :error="hasError('kbk')"
                                :error-messages="getError('kbk')"
                                :items="selectors.kbk"
                                label="КБК"
                                v-model="value.kbk">
                            <template slot="label">КБК<span class="red--text">*</span>:</template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.text}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-text-field :error="hasError('fee')"
                                      :error-messages="getError('fee')"
                                      label="Комиссия"
                                      type="number"
                                      v-model.number="value.fee"
                        >
                            <template slot="label">Комиссия<span class="red--text">*</span>:</template>
                        </v-text-field>
                        <v-autocomplete
                                :error="hasError('category')"
                                :error-messages="getError('category')"
                                :items="selectors.category"
                                item-text="name"
                                item-value="id"
                                label="Категория"
                                v-model="value.category">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.name}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-autocomplete
                                :error="hasError('payAttribute')"
                                :error-messages="getError('payAttribute')"
                                :items="selectors.payAttribute"
                                label="Признак способа расчёта"
                                v-model="value.payAttribute">
                            <template slot="label">Признак способа расчёта<span class="red--text">*</span>:</template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.text}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-autocomplete
                                :error="hasError('taxID')"
                                :error-messages="getError('taxID')"
                                :items="selectors.taxID"
                                label="Код налога"
                                v-model="value.taxID">
                            <template slot="label">Код налога<span class="red--text">*</span>:</template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.text}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                                :error="hasError('accountDefaultID')"
                                :error-messages="getError('accountDefaultID')"
                                label="Идентификатор счета в банке (оплата картой)"
                                v-model="value.accountDefaultID">
                            <template slot="label">Идентификатор счета в банке (оплата картой)<span class="red--text">*</span>:
                            </template>
                        </v-text-field>
                      <v-text-field
                          :error="hasError('accountSBPID')"
                          :error-messages="getError('accountSBPID')"
                          label="Идентификатор счета в банке (оплата через СБП)"
                          v-model="value.accountSBPID">
                        <template slot="label">Идентификатор счета в банке (оплата через СБП)<span class="red--text">*</span>:
                        </template>
                      </v-text-field>
                        <v-text-field :error="hasError('sort')"
                                      :error-messages="getError('sort')"
                                      label="Сортировка"
                                      type="number" v-model.number="value.sort">

                        </v-text-field>
                        <v-expansion-panels>
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header>
                                    Редактор формулы
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                  <span v-for="it in fieldForm">
                    <v-chip @click="addItem(it)" outlined> #{{it.field.id}}# - {{it.field.title}}</v-chip>
                  </span>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-text-field
                                :error="hasError('formula')"
                                :error-messages="getError('formula')"
                                label="Формула расчета"
                                v-model="value.formula"></v-text-field>
                        <v-file-input
                                :error="hasError('image')"
                                :error-messages="getError('image')"
                                accept="image/jpeg,image/png"
                                label="Файл"
                                v-if="typeof value.image !== 'string'"
                                v-model="value.image"
                        ></v-file-input>

                        <v-row class="text-left" v-else>
                            <v-col class="text-left py-0" cols="12">
                                Файл
                            </v-col>
                            <v-col>
                                <a :href="value.image" target="_blank">{{value.image}}</a>
                            </v-col>
                            <v-col cols="2">
                                <v-btn @click="value.image=null" icon>
                                    <v-icon>clear</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-checkbox
                                :error="hasError('printPay')"
                                :error-messages="getError('printPay')"
                                label="Форма печати"
                                v-model="value.printPay">

                        </v-checkbox>
                        <v-checkbox
                          :error="hasError('published')"
                          :error-messages="getError('published')"
                          label="Опубликована"
                          v-model="value.published">
                        </v-checkbox>
                        <v-checkbox
                            :error="hasError('sendBookkeeping')"
                            :error-messages="getError('sendBookkeeping')"
                            label="Отправка данных в бухгалтерию"
                            v-model="value.sendBookkeeping">
                        </v-checkbox>
                        <v-checkbox
                            :error="hasError('sendChek')"
                            :error-messages="getError('sendChek')"
                            label="Отправка чека"
                            v-model="value.sendChek">
                        </v-checkbox>
                        <v-checkbox
                            :error="hasError('service')"
                            :error-messages="getError('service')"
                            label="Служебная форма оплаты"
                            v-model="value.service">
                        </v-checkbox>
                      <v-checkbox
                          :error="hasError('nonCache')"
                          :error-messages="getError('nonCache')"
                          label="Полная сумма оплаты"
                          v-model="value.nonCache">
                      </v-checkbox>
                      <v-checkbox
                          :error="hasError('advancePayment')"
                          :error-messages="getError('advancePayment')"
                          label="Полная сумма оплаты предоплатой (зачётом аванса)"
                          v-model="value.advancePayment">
                      </v-checkbox>
                        <v-alert type="error" v-if="hasError('field')">
                            {{getError('field')}}
                        </v-alert>
                        <v-alert type="error" v-if="hasError('non_field_errors')">
                            <div v-for="field in getError('non_field_errors')">
                                {{String(field)}}
                            </div>
                        </v-alert>
                        <v-alert type="error" v-if="hasError('detail')">
                            {{getError('detail')}}
                        </v-alert>
                        <v-btn @click="applyFile" color="primary">Сохранить</v-btn>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

    import UserDataFormMixin from '@/mixins/UserDataFormMixin'
    import {makeGetRequest, makePostRequestFile} from "@/helper/requests";
    import urls from '@/urls/helper.js'

    export default {
        name: "Courses",
        mixins: [UserDataFormMixin],
        props: {
            id: Number,
            name: String,
            formula: String,
            category: Object,
            sort: Number,
            published: Boolean,
            sendBookkeeping: Boolean,
            sendChek: Boolean,
            service: Boolean,
            accountDefaultID: String,
            accountSBPID: String,
            taxID: String,
            payAttribute: String,
            kbk: String,
            fee: String,
            description: String,
            field: Object,
            image: String,
            printPay: Boolean,
            nonCache: Boolean,
            advancePayment: Boolean,
        },
        data() {
            return {
                URL_CREATE: urls.getPayUrlWithParameters('admin|pay|models|form-list', 'create'),
                URL_UPDATE: urls.getPayUrlWithParameters('admin|pay|models|form-detail', 'update'),
                fieldForm: [],
                selectors: {
                    category: [],
                    taxID: [],
                    payAttribute: [],
                    kbk: [],
                },
                value: {
                    id: this.id || '',
                    name: this.name || '',
                    description: this.description || '',
                    kbk: this.kbk || "0",
                    fee: this.fee || 0,
                    formula: this.formula || '',
                    accountDefaultID: this.accountDefaultID || '',
                    accountSBPID: this.accountSBPID || '',
                    category: null,
                    payAttribute: this.payAttribute || null,
                    taxID: this.taxID || null,
                    image: this.image || null,
                    sort: this.sort || 0,
                    published: this.published ,
                    sendBookkeeping: this.sendBookkeeping ,
                    sendChek: this.sendChek || false,
                    service: this.service || false,
                    printPay: this.printPay || false,
                    nonCache: this.nonCache || false,
                    advancePayment: this.advancePayment || false,
                },
            }
        },
        methods: {
            applyFile() {
                let url = this.URL_CREATE
                let method = 'POST'
                if (this.id) {
                    url = urls.getPayUrlWithParameters('admin|pay|models|form-detail', 'update', {id: this.id})
                    method = 'PATCH'
                }
                var formData = new FormData();

                if ((typeof this.value.image === 'object' && this.value.image !== null)
                    || typeof this.value.image === 'string') {
                    if (typeof this.value.image !== 'string')
                        formData.append('image', this.value.image)

                    formData.append('image', this.value.image)

                    formData.append('name', this.value.name)
                    formData.append('description', this.value.description)
                    formData.append('sort', this.value.sort)
                    if (this.value.category !== null)
                        formData.append('category', this.value.category)
                    if (this.value.kbk !== null)
                        formData.append('kbk', this.value.kbk)
                    if (this.value.fee !== null)
                        formData.append('fee', this.value.fee)
                    if (this.value.formula !== null)
                        formData.append('formula', this.value.formula)
                    if (this.value.accountDefaultID !== null)
                        formData.append('accountDefaultID', this.value.accountDefaultID)
                    if (this.value.accountSBPID !== null)
                      formData.append('accountSBPID', this.value.accountSBPID)
                    if (this.value.payAttribute !== null)
                        formData.append('payAttribute', this.value.payAttribute)
                    if (this.value.taxID !== null)
                        formData.append('taxID', this.value.taxID)
                    if (this.value.published !== null)
                        formData.append('published', this.value.published)
                    if (this.value.sendBookkeeping !== null)
                        formData.append('sendBookkeeping', this.value.sendBookkeeping)
                    if (this.value.sendChek !== null)
                        formData.append('sendChek', this.value.sendChek)
                    if (this.value.service !== null)
                        formData.append('service', this.value.service)
                    if (this.value.printPay !== null)
                      formData.append('printPay', this.value.printPay)
                    if (this.value.nonCache !== null)
                      formData.append('nonCache', this.value.nonCache)
                    if (this.value.advancePayment !== null)
                      formData.append('advancePayment', this.value.advancePayment)
                    let status = true;

                    makePostRequestFile(url, formData, method)
                        .then(resp => {
                            if (!resp.ok) {
                                status = false;
                            }
                            return resp.json();
                        })
                        .then(json => {
                            if (status) {
                                this.$emit('addItem', json);
                            } else {
                                this.receiveErrors(json);
                            }
                        })
                } else {
                    this.apply();
                }
            },
            addItem(it) {
                this.value.formula = this.value.formula + "#" + it.field.id + "#"

            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    if (data === 'category')
                        this.selectors[data].push({name: 'Не указано', id: null})
                })
            },
            closeDialog() {
                this.$emit('ended', '')
            },
        },
        created() {
            let url = urls.getPayUrlWithParameters('admin|pay|models|category-list', 'list')
            this.loadSelector("category", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|form_pay_attribute_choices', 'get')
            this.loadSelector("payAttribute", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|form_tax_id_choices', 'get')
            this.loadSelector("taxID", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|kbk_choices', 'get')
            this.loadSelector("kbk", url)
            if (this.field) {
                makeGetRequest(
                    urls.getPayUrlWithParameters('admin|pay|models|form_field-list', 'list', null, this.field.get_params)).then(resp => {
                    return resp.json()
                }).then(json => {
                    this.fieldForm = []
                    for (let val in json) {
                        if (Number(json[val].field.type) === 0 || Number(json[val].field.type) === 5)
                            this.fieldForm.push(json[val])
                    }
                })
            }
            if (this.category)
                this.value.category = this.category.id
        }
    }

</script>

<style scoped>

</style>
