<template>
    <div>
        <v-layout justify-center>
            <v-flex>
                <v-card ref="form">
                    <v-toolbar color="primary" dark style="position: sticky; z-index: 10000;top: 0">
                        <v-toolbar-title>
                            <span v-if="!!id && !copyField">Изменить значения списока выбора</span>
                            <span v-else> <div>Добавить значения списока выбора</div></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-toolbar-items>
                                <v-btn @click="closeDialog" class="pa-0 ma-0" dark icon style="left: +16px;">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-autocomplete
                                :error="hasError('field')"
                                :error-messages="getError('field')"
                                :items="selectors.field"
                                item-text="title"
                                item-value="id"
                                label="Поле"
                                v-model="value.field">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.title}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-autocomplete
                                ref="autocomplete"
                                v-model="value.parentSelectorValue"
                                :items="selectors.parentSelectorValue"
                                item-value="value"
                                :error="hasError('parentSelectorValue')"
                                :error-messages="getError('parentSelectorValue')"
                                :auto-select-first="selectFirst"
                                clearable
                                @click:clear="clearSearch"
                                label="Родительское значение селектора"
                                placeholder="Для поиска начните вводить"
                        >
                            <template v-slot:append-item v-if="nextPage">
                                <v-btn block @click="loadDataSel('',nextPage,false)"> Ещё значения</v-btn>
                            </template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <div class="text-left"
                                         style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                                        {{data.item.text}}
                                    </div>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-text-field :error="hasError('title')"
                                      :error-messages="getError('title')"
                                      label="Заголовок"
                                      v-model="value.title"
                        ></v-text-field>
                        <v-text-field :error="hasError('sort')" :error-messages="getError('sort')"
                                      label="Сортировка"
                                      type="number"
                                      v-model.number="value.sort"
                        ></v-text-field>
                        <v-text-field :error="hasError('value')"
                                      :error-messages="getError('value')"
                                      label="Значение"
                                      type="number"
                                      v-model.number="value.value"
                        ></v-text-field>
                        <v-alert type="error" v-if="hasError('non_field_errors')">
                            {{getError('non_field_errors')}}
                        </v-alert>
                        <v-alert type="error" v-if="hasError('detail')">
                            {{getError('detail')}}
                        </v-alert>
                        <v-btn @click="apply" color="primary">Сохранить</v-btn>
                    </v-card-text>

                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

    import UserDataFormMixin from '@/mixins/UserDataFormMixin'
    import {loadData, makeGetRequest} from "@/helper/requests";
    import urls from '@/urls/helper.js'

    export default {
        name: "Courses",
        mixins: [UserDataFormMixin],
        props: {
            id: Number,
            parentSelectorValue: String,
            copyField: Boolean,
        },
        data() {
            return {
                URL_CREATE: urls.getPayUrlWithParameters('admin|pay|models|selector_value-list', 'create'),
                URL_UPDATE: urls.getPayUrlWithParameters('admin|pay|models|selector_value-detail', 'update'),
                selectors: {
                    field: [],
                    parentSelectorValue: [],
                },
                selectFirst: false,
                search: null,
                awaitingSearch: false,
                nextPage: null,
                value: {
                    id: this.id || undefined,
                    title: '',
                    sort: 0,
                    value: null,
                    parentSelectorValue: null,
                    field: null,
                },
            }
        },

        watch: {
            search(val, old) {
                this.loadDataSel(val, undefined, true)
            }
        },

        methods: {
            clearSearch() {
                this.search = ''
            },
            loadDataSel(val, nextPage = undefined, selectFirst = false) {
                this.selectFirst = selectFirst
                let url = urls.getPayUrlWithParameters('admin|pay|selectors|selector_value', 'get', null, {'search': val})
                if (nextPage)
                    url = this.nextPage
                if (!this.awaitingSearch) {
                    setTimeout(() => {

                        makeGetRequest(url).then(resp => {
                            return resp.json()
                        }).then(json => {
                            if (nextPage) {
                                this.selectors.parentSelectorValue = this.selectors.parentSelectorValue.concat(json.results);
                            } else
                                this.selectors.parentSelectorValue = json.results
                            this.nextPage = json.next
                        })
                        this.awaitingSearch = false;
                    }, 500); // 1 sec delay
                }
                this.awaitingSearch = true;
            },
            loadDataSelector() {
              let name, method

              if (this.copyField) {
                name = 'admin|pay|models|selector_value-detail'
                method = 'retrieve'
              }
              else {
                name = 'admin|pay|models|selector_value-detail'
                method = 'update'
              }

              let url = urls.getPayUrlWithParameters(name, method, {id: this.id})

              loadData(url, (json) => {
                this.awaitingSearch = true;
                this.value = json
                this.value.parentSelectorValue = json.parentSelectorValue
                // this.value.field = this.field
                if (json.parentSelectorValue) {
                    this.selectors.parentSelectorValue = [{
                        "value": json.parentSelectorValue,
                        "text": this.parentSelectorValue
                    }]
                } else {
                    this.selectors.parentSelectorValue = [{
                        "value": null,
                        "text": "Не выбрано"
                    }]
                }

                if (this.copyField) {
                  delete this.value.id
                  delete this.$props.id
                }

                setTimeout(() => {
                    this.awaitingSearch = false;
                }, 1000);
              })
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    if (data === 'parentSelectorValue')
                        this.selectors[data].push({text: 'Не указано', value: null})
                })
            },
            closeDialog() {
                this.$emit('ended', '')
            },
        },
        created() {
            let url = urls.getPayUrlWithParameters('admin|pay|models|field-list', 'list') + '?type=5'
            this.loadSelector("field", url)
            if (this.id)
                this.loadDataSelector()
            else
                this.loadDataSel("")
        }
    }

</script>

<style scoped>

</style>
