<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container v-if="printForm.length === 0 && loading">
      <div class="d-flex justify-center align-center" style="min-height: 30pc">
      <v-alert type="info" outlined>
        <div>Сервис платной печати доступен только обучающимся.<br>
          Для работников сервис является бесплатным.</div>
      </v-alert>
      </div>
    </v-container>
    <v-card v-else flat :key="item.id" v-for="item in printForm">
      <v-card-title class="title style-color-main-gray">
        <span>{{ item.name }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-row class="pt-4 text-left align-center">
        <v-col cols="12" md="6">
           <span class="text-h5 pl-4">
            Баланс на {{ displayDate(today) }}
             <v-btn icon @click="loadForm">
              <v-icon>
                update
              </v-icon>
            </v-btn>
          </span>
        </v-col>
        <v-col  cols="12" md="6">
          <v-card-text class="text-h3">
            {{ item.printPayData.userBalance.amount }} руб.
          </v-card-text>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-title class="title">
        <span>Рассчитать стоимость услуг:</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
              v-model="item.price"
              class="px-3"
              label="Тип услуги"
              :items="item.allPrinter"
              item-value="price"
              :item-text="(item)=>item.translation+' ('+item.price+'₽)'"
              :rules="[(v) => !!v || 'Заполните поле']"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              v-model.number="item.countPrint"
              outlined
              persistent-hint
              class="px-3"
              type="number"
              min="1"
              label="Количество листов"
              :rules="nameRules()"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="text-left">
        <v-col>
          <v-card-text class="text-h5">
            Стоимость всей печати {{  (item.countPrint * item.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ') }} ₽
          </v-card-text>

          <v-card-text class="text-h5">
            Необходимо внести {{ (calculateSum(item)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')}}  ₽
          </v-card-text>
       </v-col>
      <v-col  class="d-flex  justify-start align-center">
        <v-btn  class="green lighten-3" @click="goToPay(item)" :disabled="!valid">
          <v-icon>account_balance_wallet</v-icon> Пополнить баланс
      </v-btn>
      </v-col>
      </v-row>
    </v-card>
  </v-form>

</template>

<script>

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import UserDataMixin from '@/mixins/UserDataMixin'
import urls from '@/urls/pay'
import {loadData} from "@/helper/requests";
import names from "@/modules/pay/routers/names";
import {getInstMenu} from "@/helper/instances";
import { isAuthenticated} from "@/helper/auth";
import moment from "moment";
import store from "@/store";

export default {
  name: "List",
  mixins: [
    PaginatedDataMapperMixin,
    UserDataMixin
  ],

  props: {},
  data() {
    return {
      selectors: {},
      printForm: [],
      today: new Date(),
      regMoney:'^[0-9]\\d*$',
      valid:true,
      loading:false,
    }
  },
  watch: {
    printForm: {
      handler() {
        this.$refs.form.validate()
      },
      deep: true
    },
  },

  methods: {
    nameRules(){
      let rules = []
      rules.push(v => !!v || "Заполните поле")
      rules.push(v => {
          const pattern = new RegExp(this.regMoney)
            return pattern.test(v) || "Заполните поле"
        })
      return rules
    },
    goToPay(item) {
      let findTotal = {}
      let formula = item.formula
      for (let obj of item.field) {
        if("#" + String(obj.field.id) + "#" === formula){
          let sum = item.countPrint * item.price - item.printPayData.userBalance.amount
          if (sum <= 0)
            sum= 0
          findTotal["ID_"+obj.field.id] = Number(sum.toFixed(2))
        }
      }
      if(Object.keys(findTotal).length > 0){

        this.$router.push({name: names.PUBLIC.FORM, params: {form: item.uid},query: findTotal })

      }else{
        this.$router.push({name: names.PUBLIC.FORM, params: {form: item.uid}})
      }
    },
    calculateSum(item){
      let sum = item.countPrint * item.price - item.printPayData.userBalance.amount
      if (sum <= 0)
          return 0
      return sum
    },
    displayDate(date) {
      let format = 'DD.MM.YYYY,  HH:mm';
      return moment(date).format(format)
    },
    loadForm() {
      this.today= new Date()
      let url = urls.PUBLIC.FORM.LIST( null, {printPay: true})
      loadData(url, (json) => {
        this.loading = true
        this.printForm = json
        this.printForm.forEach(el => {
          // let rate = el.printPayData.userBalance.rate
          this.$set(el, 'allPrinter', []);
          this.$set(el, 'countPrint', 1);
          this.$set(el, 'price', 0);
          this.$set(el, 'selectPrinter', null);
          for (let obj of el.printPayData.prices.global) {
            // el.allPrinter.push({price:obj.price * rate/100, costName:obj.costName ,translation:obj.translation} )
            el.allPrinter.push({price:obj.price, costName:obj.costName ,translation:obj.translation} )
          }
          el.allPrinter = [...new Set(el.allPrinter)].filter(word => word.price > 0);
          // el.allPrinter = el.allPrinter.filter(word => word.price > 0);
        });

      })

    },
    loadLeftmenu() {
      this.$store.commit('mainpage/changeTitle', 'Онлайн оплата услуг');
      if (isAuthenticated()) {
        this.$store.commit('leftmenu/replaceInstances', getInstMenu());
        this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
      } else {
        this.$store.commit('leftmenu/dissable');
      }
    },
    getLeftMenu() {
      let with_category_uid = (name) => {
        return {name: name, params: {category: this.$route.params.category}}
      };
      let menu = []
      menu.push({
        title: 'Перечень категорий',
        icon: 'view_list',
        included: true,
        router: with_category_uid(names.BASE),
      })
      menu.push({
        title: 'Мои транзакции',
        icon: 'local_atm',
        included: false,
        router: names.PUBLIC.TRANZACTION,
        name: names.PUBLIC.TRANZACTION,
      })
      // menu.push({
      //   title: 'Услуги печати',
      //   icon: 'print',
      //   included: false,
      //   router: names.PUBLIC.PRINT,
      //   name: names.PUBLIC.PRINT,
      // })
      return menu
    },
  },

  created() {
    if (!(store.getters['urls/getAllowed']( 'pay', 'public|pay|models|transaction-list', 'list')))
      document.location.href = `${process.env.VUE_APP_LOGIN_HOST}?from=${document.location.href}`;
    if (isAuthenticated()) {
      this.$store.commit('leftmenu/enableLeftMenu');
      this.loadForm()
    }else {
      document.location.href = `${process.env.VUE_APP_LOGIN_HOST}?from=${document.location.href}`;
    }
    this.loadLeftmenu()
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
