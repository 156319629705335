<template>
    <div>
        <v-card flat v-if="is_self_route">
            <v-container class="py-0 px-0" fluid v-if="!errLoad">
              <div v-if="load">
                <v-card-title
                    class="subprimary headline"
                    style="-moz-hyphens: auto !important; -webkit-hyphens: auto!important; -ms-hyphens: auto!important;">
                  <v-row class="text-left">

                    <v-col cols="12">
                      <div style="word-break:break-word">
                        <v-btn
                            @click="returnParent()" class="subprimary black--text" icon small>
                          <v-icon>arrow_back</v-icon>
                        </v-btn>
                        {{currentName()}}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-breadcrumbs
                    :items="currentBreadcrumbs"
                    class="hidden-sm-and-down"
                >
                  <template v-slot:divider>
                    <v-icon>forward</v-icon>
                  </template>
                </v-breadcrumbs>
                <v-data-iterator
                    :items="categoryAndForm"
                    :items-per-page.sync="categoryAndForm.length"
                    class="px-2 py-3"
                    hide-default-footer
                    no-data-text=""
                >
                  <template v-slot:default="props">
                    <v-row class="justify-center">
                      <v-col
                          :key="item.uid"
                          class="py-2"
                          cols="12"
                          md="4" v-for="item in props.items" xl="3"
                          xs="12"
                      >
                        <v-hover v-slot="{ hover }">
                          <v-card
                              :elevation="hover ? 6 : 3"
                              @click="selectObj(item)" rounded="lg"
                          >
                            <v-img
                                :src='(item.image)? item.image:imgCart'
                                height="200px"
                            >
                              <v-card-text
                                  class=" d-flex fill-height"
                                  min-height="200px"
                              >
                                <div v-if ="item.image" style="height: 200px; width: 100%; position: absolute; top: 0; left: 0; background: white; opacity: 0.5;">
                                </div>
                                <v-row
                                    class="black--text title my-auto"
                                    style="z-index: 10;"
                                >
                                  <v-col
                                      style="word-break: break-word; overflow-y: hidden; width: 100%;"
                                  >
                                    <div class="title"><span v-html="item.name"></span></div>
                                    <div class="body-2"><span v-html="item.description"></span>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-img>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
              <div style="height: 75vh" class="d-flex justify-center align-center " v-else>
                <semipolar-spinner
                    :animation-duration="2000"
                    :size="115"
                    color="#1935ff"
                />
              </div>
            </v-container>
            <v-container style="" v-else>
                <v-card>
                    <v-card-text>
                        <v-alert type="error">
                            Сервис не доступен
                        </v-alert>

                    </v-card-text>
                </v-card>
            </v-container>
        </v-card>
        <router-view :key="$route.fullPath" v-else></router-view>
    </div>
</template>

<script>
    import names from "@/modules/pay/routers/names";
    import urls from "@/urls/pay";
    import {loadData, makeGetRequest} from "@/helper/requests";
    import imgCart from "@/assets/fone.jpg"
    import {SemipolarSpinner} from "epic-spinners";

    export default {
        name: "Category",
        components: {
          SemipolarSpinner
        },
        computed: {
          /**
           * Текущий роутер
           */
            is_self_route: function () {
                return this.$route.name === names.PUBLIC.CATEGORY
            },
          /**
           * Фильтрация по категории
           */
            categoryFilter: function () {
                return this.category.filter(it => it.parentCategory === this.parent)
            },
          /**
           * Совместный список форм и категорий
           */
            categoryAndForm: function () {
                return this.categoryFilter.concat(this.form.filter(it => it.printPay == false));
            },

          /**
           * Получение текушего пути хлебных крошек
           */
            currentBreadcrumbs() {
            // Массив текущего пути
                let mass = []
                let saver = (data) => {
                    let parenr = this.category.find(it => {
                        return it.uid === data
                    })
                  // условие выхода из рекурсии
                    if (parenr) {
                        mass.push(
                            {
                                text: parenr.name,
                                activeClass: "",
                                to: {name: names.PUBLIC.CATEGORY, params: {category: parenr.uid}}
                            }
                        )
                      // рекурсивный спуск по родителям
                        saver(parenr.parentCategory)
                    }
                }
                saver(this.$route.params.category)
                mass.push({
                    text: 'Категории',
                    activeClass: "",
                    to: {name: names.BASE}
                })
                // разварачиваем путь для корректности
                return mass.reverse();
            }
        },
        data() {
            return {
                imgCart: imgCart,
                errLoad: false,
                load:false,
                category: [],
                form: [],
                form_category: [],
                parent: null,
            }
        },
        methods: {
          /**
           * Получение текущего имени категории
           */
            currentName() {
                let arr = this.category.filter(it => it.uid === this.$route.params.category)
                if (arr.length > 0) {
                    return arr[0].name
                }
                return ""
            },
          /**
           * Получение текушего родителя для кнопки назад
           */
            returnParent() {
                let arr = this.category.filter(it => it.uid === this.$route.params.category)
                if (arr.length > 0) {
                    let uid = arr[0].parentCategory
                    if (uid !== null) {
                        this.form = []
                        this.$router.push({name: names.PUBLIC.CATEGORY, params: {category: uid}})
                        this.parent = this.$route.params.category
                        if (this.parent)
                            this.loadForm()
                    } else {
                      // редирект если ошибка
                        this.$router.push({name: names.BASE})
                    }

                } else {
                  // редирект если ошибка
                    this.$router.push({name: names.BASE})
                }
            },
          /**
           * Переход по нажатой карточке в зависимости от её типа (форма или категория)
           *  @param {object} item - выбранная карточка на форме
           */
            selectObj(item) {
                if (item.category)
                    this.$router.push({name: names.PUBLIC.FORM, params: {form: item.uid}})
                else {
                    this.$router.push({name: names.PUBLIC.CATEGORY, params: {category: item.uid}})
                    this.form = []
                    this.parent = this.$route.params.category
                    this.loadForm()
                }
            },
          /**
           * Подгрузка всех доступных категорий
           */
            loadDataCategory() {
                let url = urls.PUBLIC.CATEGORY.LIST()
                if(!url)
                    this.$router.push({name: names.BASE})
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        this.errLoad = true
                    }
                    return resp.json()
                }).then(json => {
                    this.parent = this.$route.params.category
                    this.category = json
                    this.load=true
                    if (this.parent)
                        this.loadForm()
                    if (!this.$route.params.category) {
                        this.$router.push({name: names.BASE})
                    }

                })
            },
          /**
           * Подгрузка форм внутри категории
           */
            loadForm() {
                let url = urls.PUBLIC.FORM.LIST( null, {category__uid: this.$route.params.category})
                loadData(url, (json) => {
                    this.form = json
                })
            }
        },

        created() {
            this.loadDataCategory()
        }
    }
</script>

<style scoped>

</style>
