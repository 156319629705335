<template>
    <v-footer
            id="footer-pay"
            ref="footer"
            style="background-color: white"
    >
        <v-layout justify-center wrap>
            <v-flex
                    md11
                    text-xs-center
            >
                <v-card-text class="caption py-0"> К оплате принимаются следующие типы банковских карт:
                    VISA, имеющие трехзначный код CVV2;
                    Mastercard, имеющие трехзначный код CVC2;
                    МИР, имеющие трехзначный код на обратной стороне;
                </v-card-text>
                <v-card-text class="caption">
                    Безопасность платежей обеспечивается с помощью Банка-эквайера (ГАЗПРОМБАНК (Акционерное Общество)),
                    функционирующего на основе современных протоколов и технологий, разработанных платежными системами
                    МИР,
                    Visa International и Mastercard Worldwide (3D-Secure: Verified by VISA, Mastercard SecureCode,
                    MirAccept).
                    Обработка полученных конфиденциальных данных Держателя карты производится в процессинговом центре
                    Банка,
                    сертифицированного по стандарту PCI DSS.
                    Безопасность передаваемой информации обеспечивается с помощью современных протоколов обеспечения
                    безопасности в
                    сети Интернет.
                </v-card-text>
                <v-layout align-center justify-center wrap>

                    <div class="px-3">
                        <v-img
                                :src="gazprombank"
                                contain
                                flat
                                max-width="150"
                        >
                        </v-img>
                    </div>

                    <div class="px-3">
                        <v-img
                                :src="mir"
                                contain
                                flat
                                max-width="100"
                        >
                        </v-img>
                    </div>
                  <div class="px-3">
                    <v-img
                        :src="access_mir"
                        contain
                        flat
                        max-width="100"
                    >
                    </v-img>
                  </div>
                    <div class="px-3">
                        <v-img
                                :src="visa"
                                contain
                                flat
                                max-width="80"
                        >
                        </v-img>
                    </div>
                  <div class="px-3">
                    <v-img
                        :src="ver_visa"
                        contain
                        flat
                        max-width="80"
                    >
                    </v-img>
                  </div>
                    <div class="px-3">
                        <v-img
                                :src="mc"
                                contain
                                flat
                                max-width="80"
                        >
                        </v-img>
                    </div>
                  <div class="px-3">
                    <v-img
                        :src="master_secure"
                        contain
                        flat
                        max-width="80"
                    >
                    </v-img>
                  </div>
                  <div class="px-3">
                    <v-img
                        :src="cpb"
                        contain
                        flat
                        max-width="80"
                    >
                    </v-img>
                  </div>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-footer>
</template>

<script>
    import mc from "@/assets/mc.png";
    import visa from "@/assets/visa.png";
    import mir from "@/assets/mir.png";
    import gazprombank from "@/assets/gazprombank.png"
    import ver_visa from "@/assets/ver_visa.png";
    import access_mir from "@/assets/access_mir.png";
    import master_secure from "@/assets/master_secure.png"
    import cpb from "@/assets/cpb.png"

    export default {
        name: "Footer",
        data() {
            return {
                mc: mc,
                master_secure : master_secure,
                visa: visa,
                mir: mir,
                cpb:cpb,
                gazprombank: gazprombank,
                ver_visa:ver_visa,
                access_mir:access_mir
            }
        }
    }
</script>

<style scoped>

</style>
