import { render, staticRenderFns } from "./InstanceLeftMenu.vue?vue&type=template&id=b9e77770&scoped=true"
import script from "./InstanceLeftMenu.vue?vue&type=script&lang=js"
export * from "./InstanceLeftMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9e77770",
  null
  
)

export default component.exports