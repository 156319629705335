const PAY = {
    BASE: 'Pay',
    DETAIL: undefined,
    PUBLIC: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    ADMIN: {
        _BASE: undefined,
        PERMISSION: undefined,
    },
};

PAY.PUBLIC._BASE = `${PAY.BASE}|Public`
PAY.PUBLIC.TRANZACTION = `${PAY.PUBLIC._BASE}|Tranzaction`
PAY.PUBLIC.PRINT = `${PAY.PUBLIC._BASE}|Print`
PAY.PUBLIC.CATEGORY = `${PAY.PUBLIC._BASE}|Category`
PAY.PUBLIC.FORM = `${PAY.PUBLIC._BASE}|Form`
PAY.PUBLIC.FAIL = `${PAY.PUBLIC._BASE}|Fail`
PAY.PUBLIC.SUCCESS = `${PAY.PUBLIC._BASE}|Success`
PAY.PUBLIC.LIST = `${PAY.PUBLIC._BASE}|List`

PAY.ADMIN._BASE = `${PAY.BASE}|Admin`
PAY.ADMIN.PERMISSION = `${PAY.ADMIN._BASE}|Permission`
PAY.ADMIN.CATEGORY = `${PAY.ADMIN._BASE}|Category`
PAY.ADMIN.TRANSACTION = `${PAY.ADMIN._BASE}|Transaction`
PAY.ADMIN.DOCUMENT = `${PAY.ADMIN._BASE}|Document`
PAY.ADMIN.FIELD = `${PAY.ADMIN._BASE}|Field`
PAY.ADMIN.FORM = `${PAY.ADMIN._BASE}|Form`
PAY.ADMIN.FORM_DOCUMENT = `${PAY.ADMIN._BASE}|FormDocument`
PAY.ADMIN.FORM_FIELD = `${PAY.ADMIN._BASE}|FormField`
PAY.ADMIN.SELECTOR_VALUE = `${PAY.ADMIN._BASE}|SelectorValue`

export default {
    ...PAY
}
