import store from "@/store";

export function isAuthenticated() {
  return store.getters['user/isAuthenticate']
}

export function isAliveToken(success = () => {}, error = () => {}) {
  if(store.getters['user/isCheckExpired']()){
    store.dispatch('user/CHECK_TOKEN_ALIVE').then(
      (json) => {
        success(json)
      }
    ).catch(error)
  } else {
    success()
  }
}

export function goToLoginPage(){
  document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`
}
export function clearLocalstorage() {
  localStorage.removeItem('user-token'); // clear your user's token from localstorage
  localStorage.removeItem('isWorker');
  localStorage.removeItem('isStudent');
  localStorage.removeItem('username');
}
