<template>
    <div>
        <v-layout justify-center>
            <v-flex>
                <v-card ref="form">
                    <v-toolbar color="primary" dark style="position: sticky; z-index: 10000;top: 0">
                        <v-toolbar-title>
                            <span v-if="!!id">Изменить поле</span>
                            <span v-else> <div>Добавить поле</div></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-toolbar-items>
                                <v-btn @click="closeDialog" class="pa-0 ma-0" dark icon style="left: +16px;">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-textarea
                                :error="hasError('title')"
                                :error-messages="getError('title')"
                                auto-grow
                                label="Заголовок"
                                rows="1"
                                v-model="value.title">
                            <template slot="label">Заголовок<span class="red--text">*</span>:</template>
                        </v-textarea>
                        <v-textarea
                                :error="hasError('name')"
                                :error-messages="getError('name')"
                                auto-grow
                                label="Наименование"
                                rows="1"
                                v-model="value.name"></v-textarea>
                        <v-text-field
                                :error="hasError('helpText')"
                                :error-messages="getError('helpText')"
                                label="Подсказка"
                                v-model="value.helpText"></v-text-field>
                        <v-autocomplete
                                :error="hasError('type')"
                                :error-messages="getError('type')"
                                :items="selectors.type"
                                label="Тип"
                                v-model="value.type">
                            <template slot="label">Тип<span class="red--text">*</span>:</template>
                        </v-autocomplete>
                        <v-autocomplete
                                :error="hasError('metaType')"
                                :error-messages="getError('metaType')"
                                :items="selectors.metaType"
                                label="Метатип"
                                v-model="value.metaType">
                            <template slot="label">Метатип<span class="red--text">*</span>:</template>
                        </v-autocomplete>
                        <v-text-field
                                :error="hasError('regex')"
                                :error-messages="getError('regex')"
                                label="Регулярное выражение"
                                v-if="Number(value.type)<3"
                                v-model="value.regex"></v-text-field>
                        <v-textarea
                                :error="hasError('defaultValue')"
                                :error-messages="getError('defaultValue')"
                                label="Значение по умолчанию"
                                v-if="value.type ==='2'"
                                v-model="value.defaultValue"></v-textarea>
                        <v-text-field
                                :error="hasError('defaultValue')"
                                :error-messages="getError('defaultValue')"
                                label="Значение по умолчанию"
                                type="date"
                                v-else-if="value.type ==='3'"
                                v-model="value.defaultValue"></v-text-field>
                        <v-text-field
                                :error="hasError('defaultValue')"
                                :error-messages="getError('defaultValue')"
                                label="Значение по умолчанию"
                                v-else
                                v-model="value.defaultValue"></v-text-field>
                        <v-autocomplete
                                :error="hasError('document')"
                                :error-messages="getError('document')"
                                :items="selectors.document"
                                item-text="name"
                                item-value="id"
                                label="Документ"
                                v-if="value.type==='4'"
                                v-model="value.document">
                        </v-autocomplete>
                        <v-text-field
                                :error="hasError('placeholder')"
                                :error-messages="getError('placeholder')"
                                label="Заполнитель"
                                v-model="value.placeholder"></v-text-field>
                        <v-expansion-panels class="py-1" v-if="value.type ==='3'" v-model="panel">
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header>
                                    Динамическое ограничение даты
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-autocomplete
                                            :error="hasError('dateDynamicValueType')"
                                            :error-messages="getError('dateDynamicValueType')"
                                            :items="selectors.dateDynamicValueType"
                                            label="Тип расчетной единицы динамических границ даты"
                                            v-model="value.dateDynamicValueType">
                                    </v-autocomplete>
                                    <v-text-field
                                            :error="hasError('dateDynamicUpper')"
                                            :error-messages="getError('dateDynamicUpper')"
                                            label="Верхняя динамичная  граница даты"
                                            type="number"
                                            v-model.number="value.dateDynamicUpper"></v-text-field>
                                    <v-text-field
                                            :error="hasError('dateDynamicLower')"
                                            :error-messages="getError('dateDynamicLower')"
                                            label="Нижняя динамичная  граница даты"
                                            type="number"
                                            v-model.number="value.dateDynamicLower"></v-text-field>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-expansion-panels class="py-1" v-if="value.type ==='3'" v-model="panel2">
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header>
                                    Статическое ограничение даты
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-text-field
                                            :error="hasError('dateStaticUpper')"
                                            :error-messages="getError('dateStaticUpper')"
                                            label="Верхняя статичная граница даты"
                                            type="date"
                                            v-model="value.dateStaticUpper"></v-text-field>
                                    <v-text-field
                                            :error="hasError('dateStaticLower')"
                                            :error-messages="getError('dateStaticLower')"
                                            label="Нижняя статичная граница даты"
                                            type="date"
                                            v-model="value.dateStaticLower"></v-text-field>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-checkbox
                                :error="hasError('readOnly')"
                                :error-messages="getError('readOnly')"
                                label="Только чтение"
                                v-model="value.readOnly"></v-checkbox>
                        <v-checkbox
                                :error="hasError('hidden')"
                                :error-messages="getError('hidden')"
                                label="Скрытое"
                                v-model="value.hidden"></v-checkbox>
                        <v-checkbox
                                :error="hasError('required')"
                                :error-messages="getError('required')"
                                label="Обязательное"
                                v-model="value.required"></v-checkbox>
                        <v-alert type="error" v-if="hasError('non_field_errors')">
                            <div v-for="field in getError('non_field_errors')">
                                {{String(field)}}
                            </div>
                        </v-alert>
                        <v-alert type="error" v-if="hasError('detail')">
                            {{getError('detail')}}
                        </v-alert>
                        <v-alert type="error" v-if="hasError('id')">
                            {{getErrorArray('id')}}
                        </v-alert>
                        <v-btn @click="apply" color="primary">Сохранить</v-btn>
                    </v-card-text>

                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

    import UserDataFormMixin from '@/mixins/UserDataFormMixin'
    import {makeGetRequest} from "@/helper/requests";
    import urls from '@/urls/helper.js'

    export default {
        name: "Courses",
        mixins: [UserDataFormMixin],
        props: {
            id: Number,
            title: String,
            helpText: String,
            regex: String,
            type: String,
            defaultValue: String,
            metaType: String,
            dateStaticUpper: String,
            dateStaticLower: String,
            dateDynamicLower: Number,
            dateDynamicUpper: Number,
            dateDynamicValueType: String,
            readOnly: Boolean,
            placeholder: String,
            name: String,
            hidden: Boolean,
            required: Boolean,
            document: String,
        },
        watch: {
            'value.type': function (newv, oldv) {
                if (newv === '4') {
                    this.value.defaultValue = 1
                }
            },
        },
        data() {
            return {
                panel: 0,
                panel2: null,
                URL_CREATE: urls.getPayUrlWithParameters('admin|pay|models|field-list', 'create'),
                URL_UPDATE: urls.getPayUrlWithParameters('admin|pay|models|field-detail', 'update'),
                selectors: {
                    type: [],
                    metaType: [],
                    document: [],
                    dateDynamicValueType: [],
                },
                value: {
                    id: this.id || undefined,
                    title: this.title || '',
                    helpText: this.helpText || '',
                    regex: this.regex || '',
                    name: this.name || '',
                    type: this.type || null,
                    metaType: this.metaType || null,
                    dateStaticUpper: this.dateStaticUpper || null,
                    dateStaticLower: this.dateStaticLower || null,
                    dateDynamicLower: this.dateDynamicLower || null,
                    dateDynamicUpper: this.dateDynamicUpper || null,
                    dateDynamicValueType: this.dateDynamicValueType || null,
                    defaultValue: this.defaultValue || '',
                    readOnly: this.readOnly || false,
                    required: this.required || false,
                    placeholder: this.placeholder || '',
                    hidden: this.hidden || false,
                    document: this.document || null,
                },
            }
        },
        methods: {
            getErrorArray: function (field) {
                if (
                    field in this.errors
                    && this.errors[field] !== undefined
                    && this.errors[field] !== null
                )
                    if (this.errors[field].length > 0) {
                        let err = ""
                        let arError = this.errors[field]
                        for (let i in arError) {
                            let anskey = Object.keys(arError[i])
                            for (let j in anskey)
                                err = err + arError[i][anskey[j]] + ";"
                        }
                        return err;
                    }

                return null;
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    if ((data !== "type") && (data !== "document"))
                        this.selectors[data].push({text: 'Не указано', value: null})
                })
            },
            closeDialog() {
                this.$emit('ended', '')
            },
        },
        created() {
            let url = urls.getPayUrlWithParameters('admin|pay|choices|field_type_choices', 'get')
            this.loadSelector("type", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|field_metatype_choices', 'get')
            this.loadSelector("metaType", url)
            url = urls.getPayUrlWithParameters('admin|core|models|document-list', 'list')
            this.loadSelector("document", url)
            url = urls.getPayUrlWithParameters('admin|pay|choices|date_dynamic_value_type_choices', 'get')
            this.loadSelector("dateDynamicValueType", url)
        }
    }

</script>

<style scoped>

</style>
