import {clearUserAuth,  makeProfileUrl} from "../helper";
import {
    makeGetRequest,
    makeDeleteRequest,
} from "@/helper/requests.js";
export default {
    data: function () {
        return {
            loadingPage: false,
            errorLoadPage: '',
            items: {},
            selected: {},
            deletable: {},
            errors: {},
            opened_form: false,
            opened_confirm: false,
            URL_LIST: '',
            URL_DELETE: '',

            testItemsR: [],
        }
    },
    methods: {
        getListUrl() {
            return makeProfileUrl(
                this.URL_LIST
            );
        },
        getDetailUrl(id) {
            return makeProfileUrl(
                this.URL_DELETE
            ).replace('<id>', id);
        },
        addItem: function (item) {
            // items = items;
            this.items.push(item);
            this.closedForm()
        },
        getItems: function () {
            this.loadingPage = false;
            makeGetRequest(
                this.getListUrl()
            )
                .then(resp => {
                    if (resp.status === 401) {
                        clearUserAuth()
                    }
                    return resp.json()
                }).then(json => {
                this.items = json;
                this.loadingPage = false;
            });
        },
        openConfirmDelete: function (obj) {
            this.deletable = obj;
            this.opened_confirm = true;
        },

        closeConfirmDelete: function () {
            this.opened_confirm = false;
            setTimeout(() => {
                this.deletable = null;
            }, 500);

        },

        deleteItem: function (id) {
            makeDeleteRequest(
                this.getDetailUrl(id)
            ).then(resp => {
                if (resp.ok) {
                        this.getItems()
                        this.closeConfirmDelete()
                }
                if (resp.status === 401) {
                    clearUserAuth()
                }
                return resp.json()
            }).then(json => {
                if (json.error !== undefined)
                    alert(json.error);
                this.closeConfirmDelete()
            })
        },
        refreshItem: function (item) {
            let idx = this.items.findIndex((val, idx, obj) => val.id === item.id);
            this.$set(this.items, idx, item);
            this.closedForm()
        },
        updateItem: function (item) {
            this.selected = item;
            this.opened_form = true
        },
        closedForm: function () {
            this.selected = {};
            this.opened_form = false
        },
        getChoices: function (url) {
            return makeGetRequest(url).then(resp => {
                return resp.json()
            }).then(json => {
                return json
            });
        },
        getDisplay: function (value, selector) {
            if (selector) {
                let res = selector.find((el) => {
                    if (el.value === value)
                        return true
                });
                if (res)
                    return res.text;
                else
                    return null;
            } else
                return null;
        },
        getDisplaySelect(value, selector) {
            if (selector) {
                let res = selector.find((el) => {
                    if (el.value === value)
                        return true
                });
                if (res)
                    return res.text;
                else
                    return null;
            } else
                return null;
        },
        getDisplayBool(value, istrue, isfalse) {
            if (value)
                return istrue;
            else
                return isfalse;
        },
    },
    created() {
        // console.log(this.URL_LIST)
        // this.getItems()
    }
}
