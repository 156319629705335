<template>
    <div class="page-content" style="margin: 0px; width: 100%">
        <!--    <PageHeader></PageHeader>-->
        <v-container fluid v-if="is_self_route">
            <v-row class="justify-center  ">
                <v-col class="justify-center  " cols="10">
                    <v-row class="justify-center">
                        <v-col :key="`card_${index}`" cols="12" md="6" v-for="(item, index) in massRouter" xl="4">
                            <v-card class="ma-3 justify-center" hover>
                                <v-img
                                        :src=imgCart
                                        height="200px"
                                >
                                    <router-link :to="{name: item.router}" style="text-decoration: none">
                                        <div class="adminCart white--text">{{ item.title }}</div>
                                    </router-link>
                                </v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <router-view v-else></router-view>
    </div>
</template>

<script>
    import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
    import names from "@/modules/pay/routers/names";
    import PageHeader from '@/modules/menus/PageHeader.vue'
    import imgCart from "@/assets/cart.png"
    import {isAuthenticated} from "@/helper/auth";
    import store from "@/store";


    export default {
        name: "AdminMenu",
        components: {PageHeader},
        data: function () {
            return {
                imgCart: imgCart,
                massRouter: [],
                names: names

            }
        },
        computed: {
          /**
           * проверка текущего роутера
           */
            is_self_route: function () {
                return this.$route.name === names.ADMIN._BASE
            },
        },
        created() {
            if (!store.getters['urls/getAllowed']('pay', 'admin|pay|models|transaction-list', 'list'))
                this.$router.push({name: names.BASE})
            this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.ADMIN._BASE));
            this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
            if (isAuthenticated() || this.$route.name === names.ADMIN._BASE) {
                this.$store.commit('leftmenu/replaceInstances', getInstMenu());
                this.$store.commit('leftmenu/enableLeftMenu');
                this.$store.commit('leftmenu/hide');
            }
        },
        beforeRouteUpdate(to, from, next) {
            // this.$store.commit('mainpage/changeTitle', 'Администрирование системы');
            this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.ADMIN._BASE));
            this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
            next()
        },
        methods: {
          /**
           * Получение полей форм
           */
            getLeftMenu() {
                let menu = []
                menu.push({title: 'Главная', icon: 'view_quilt', router: names.ADMIN._BASE, name: names.ADMIN._BASE,})

                if (store.getters['urls/getAllowed']('pay', 'admin|pay|models|category-list', 'list'))
                    menu.push({
                        title: 'Категории',
                        icon: 'view_module',
                        router: names.ADMIN.CATEGORY,
                        name: names.ADMIN.CATEGORY,
                    })
                if (store.getters['urls/getAllowed']('pay', 'admin|pay|models|field-list', 'list'))
                    menu.push({title: 'Поля', icon: 'subject', router: names.ADMIN.FIELD, name: names.ADMIN.FIELD,})
                if (store.getters['urls/getAllowed']('pay', 'admin|pay|models|selector_value-list', 'list'))
                    menu.push({
                        title: 'Списки выбора',
                        icon: 'grading',
                        router: names.ADMIN.SELECTOR_VALUE,
                        name: names.ADMIN.SELECTOR_VALUE,
                    })
                if (store.getters['urls/getAllowed']('pay', 'admin|pay|models|form_document-list', 'list'))
                    menu.push({
                        title: 'Документы',
                        icon: 'file_present',
                        router: names.ADMIN.DOCUMENT,
                        name: names.ADMIN.DOCUMENT,
                    })
                if (store.getters['urls/getAllowed']('pay', 'admin|pay|models|form-list', 'list'))
                    menu.push({
                        title: 'Формы',
                        icon: 'dashboard_customize',
                        router: names.ADMIN.FORM,
                        name: names.ADMIN.FORM,
                    })
                if (store.getters['urls/getAllowed']('pay', 'admin|pay|models|transaction-list', 'list'))
                    menu.push({
                        title: 'Транзакции',
                        icon: 'request_page',
                        router: names.ADMIN.TRANSACTION,
                        name: names.ADMIN.TRANSACTION,
                    })
                if (store.getters['urls/getAllowed']('pay', 'admin|permissions|models|role_user-list', 'list'))
                    menu.push({
                        title: 'Права доступа',
                        icon: 'privacy_tip',
                        router: names.ADMIN.PERMISSION,
                        name: names.ADMIN.PERMISSION,
                    })
                this.massRouter = menu.slice(1)
                return menu
            }
        },
    }
</script>

<style scoped>

    #footer-pay {
        display: none !important;
    }

    .adminCart {
        height: 200px;
        text-align: center;
        line-height: 200px;
        font-size: 28px;
    }
</style>
