const state = {
    title: 'Профиль пользователя',
}

const mutations = {
    changeTitle: function (state, title) {
        state.title = title;
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
