<template>
  <!-- TODO: Width need been check, now is 256px, old is 300px-->
  <v-navigation-drawer
      clipped
      app
      v-if="leftmenu.enabled"
      v-model="leftmenu.show"
      id="main-left-menu"
      width="300"
  >
    <CurrentLeftMenu :items="leftmenu.items" class="overflow-hidden"></CurrentLeftMenu>
    <template v-slot:append>
      <InstanceLeftMenu :instances="leftmenu.instances" :current_instance="leftmenu.currentInstance"></InstanceLeftMenu>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex'
import CurrentLeftMenu from "@/modules/menus/left_menu/CurrentLeftMenu";
import InstanceLeftMenu from "@/modules/menus/left_menu/InstanceLeftMenu";

export default {
  name: 'leftmenu',
  components: {CurrentLeftMenu, InstanceLeftMenu},
  data() {
    return {}
  },
  computed: mapState({
    leftmenu: state => state.leftmenu,
  }),
  methods: {},
  created() {}
}

</script>
