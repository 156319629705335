import helper from "./helper";
// import user from "../store/modules/user";

let getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters;

export default {
    getAccountsUrlWithParameters,
    getByRecievedUrlData: (url, gets = null) => getAccountsUrlWithParameters(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    PROFILE: {
        DETAIL:
            (user_uid, gets = null) => getAccountsUrlWithParameters(
                "users|user|profile",
                "get",
                {user_uid: user_uid},
                gets
            ),
    },
}
