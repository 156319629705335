import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify';
import ru from "vuetify/es5/locale/ru";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    lang: {
        locales: {ru, en},
        current: 'ru',
    }
});
