<template>
    <v-container>
        <div class="pb-4">
            <v-expansion-panels>
                <v-expansion-panel
                >
                    <v-expansion-panel-header>
                        <div>
                            <v-icon>search</v-icon>
                            Поиск
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-0">
                        <v-text-field

                                @change="searchData"

                                messages="Для поиска нажмите Enter или кнопку найти"
                                outlined
                                placeholder="Поиск"
                                v-model="search.value"
                        >
                        </v-text-field>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn @click="searchData">Найти</v-btn>
                            <v-btn @click="search.value='';getItems()">Сбросить фильтр</v-btn>
                        </v-card-actions>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <v-card>
            <v-card-title class="title style-color-main-gray">
                <span>Список полей выбора</span>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        @click="changeExpand"
                        class="mr-2"
                        medium
                        v-on="on"
                    >
                      {{ expand_fields ? 'expand_more' : 'expand_less' }}
                    </v-icon>
                  </template>
                  <span>{{ expand_fields ? 'Свернуть все' : 'Развернуть все' }}</span>
                </v-tooltip>

                <v-btn @click="opened_form = true" color="secondary" text>
                    <v-icon>add</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                @click="getItems()"
                                class="ml-2"
                                medium
                                v-on="on"
                        >
                            update
                        </v-icon>
                    </template>
                    <span>Обновить данные</span>
                </v-tooltip>
            </v-card-title>

            <v-data-table
                    ref="expandableTable"
                    :footer-props="{'items-per-page-options':[10, 50, 250, 500, 1000], 'items-per-page-text': 'Число элементов'}"
                    :headers="headers"
                    :items="items"
                    :items-per-page.sync="pagination.size"
                    :loading="pagination.loading"
                    :page.sync="pagination.page"
                    :server-items-length="pagination.count"
                    group-by="field"
                    no-data-text="Данные отсутствуют"
                    no-results-text="Ничего не было найдено"
            >
                <template v-slot:group.header="item">
                    <td :colspan="headers.length" class="text-left">
                        <v-btn @click="item.toggle" icon>
                            <v-icon>{{ item.isOpen ? 'remove' : 'add' }}</v-icon>
                        </v-btn>
                        {{ getDisplay( item.group, selectors.field) }}
                    </td>
                </template>
                <template v-slot:item.parentSelectorValue="{ item }">
                    {{   item.parentSelectorValue  }}
                </template>
                <template v-slot:item.btn="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            @click="cloneItem(item)"
                            class="mr-3"
                            medium
                            v-on="on"
                        >
                          library_add
                        </v-icon>
                      </template>
                      <span>Создать на основании</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="updateItem(item)"
                                    medium
                                    v-on="on"
                            >
                                edit
                            </v-icon>
                        </template>
                        <span>Редактировать</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="openConfirmDelete(item)"
                                    class="ml-3"
                                    medium
                                    v-on="on"
                            >
                                delete
                            </v-icon>
                        </template>
                        <span>Удалить</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <v-dialog @input="closedForm" persistent v-model="opened_form" width="500">
                <Form @addItem="addItem"
                      @ended="closedForm"
                      @refreshItem="refreshItem"
                      v-bind="selected"
                      v-bind:info="$route.params.id"
                      v-if="opened_form"
                >
                </Form>
            </v-dialog>
            <v-dialog persistent v-model="opened_confirm" width="500">
                <v-card v-if="deletable">
                    <v-card-title>Подтвердите удаление значения</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        Вы уверены что хотите удалить {{deletable.title}} ?<br>
                    </v-card-text>
                    <v-card-actions>
                        <v-flex>
                            <v-btn
                                    @click="deleteItem(deletable.id)"
                                    color="warning"
                                    text
                            >
                                Удалить
                            </v-btn>
                            <v-btn
                                    @click="closeConfirmDelete"
                                    text
                            >Отмена
                            </v-btn>
                        </v-flex>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
    import OneFieldBaseSearchMixin from "@/mixins/OneFieldBaseSearchMixin";
    import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
    import UserDataMixin from '@/mixins/UserDataMixin'
    import urls from '@/urls/helper.js'
    import Form from "./Form"
    import {clearUserAuth, makeProfileUrl} from "@/helper";
    import {loadData, makeGetRequest, makeDeleteRequest} from "@/helper/requests";

    export default {
        name: "List",
        mixins: [
            PaginatedDataMapperMixin,
            UserDataMixin,
            OneFieldBaseSearchMixin
        ],
        components: {
            Form
        },
        props: {},
        data() {
            return {
                expand_fields: true,
                selectors: {
                    field: [],
                    parentSelectorValue: []
                },
                search: {
                    parameter: 'search'
                },
                items: [],
                URL_LIST: urls.getPayUrlWithParameters('admin|pay|models|selector_value-list', 'list'),
                URL_DELETE: urls.getPayUrlWithParameters('admin|pay|models|selector_value-detail', 'destroy'),
                headers: [
                    {text: 'Наименование', sortable: false, align: 'left', groupable: false, value: 'title'},
                    {text: 'Поле ', sortable: false, align: 'left', value: 'field'},
                    {
                        text: 'Родительское значение селектора ',
                        sortable: false,
                        align: 'left',
                        value: 'parentSelectorValue'
                    },
                    {text: 'Значение', sortable: false, align: 'left', groupable: false, value: 'value'},
                    {text: 'Сортировка', sortable: false, align: 'left', groupable: false, value: 'sort'},
                    {text: 'Действия', sortable: false, width: 150, align: 'right', groupable: false, value: 'btn'},
                ],
            }
        },
        methods: {
            changeExpand() {
              this.expand_fields = !this.expand_fields
              const self = this;
              for (const name of Object.keys(self.$refs.expandableTable.openCache)) {
                self.$refs.expandableTable.openCache[name] = this.expand_fields;
              }
            },
            cloneItem(item) {
                this.selected = item;
                this.selected.copyField = true
                this.opened_form = true
            },
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    this.selectors[data].push({title: 'Не указано', id: null})
                })
            },
            getItems() {
                this.closedForm()
                this.load();
            },
            addItem() {
                this.closedForm()
                this.load(this.getCurrentLoadUrl());
            },
            deleteItem: function (id) {
              let url = makeProfileUrl(
                  this.URL_DELETE
              )
                  .replace('<id>', id);
              makeDeleteRequest(url).then(resp => {
                if (resp.ok) {
                  let idx = this.items.findIndex((val, idx, obj) => val.id === id);
                  this.items.splice(idx, 1);
                  this.closeConfirmDelete()
                }
                if(resp.status === 401) {
                  clearUserAuth()
                }
                return resp.json()
              }).then(json => {
                if(json.error !== undefined)
                  alert(json.error)

                this.closeConfirmDelete()
                this.load(this.getCurrentLoadUrl());
              })
            },
            refreshItem() {
                this.closedForm()
                this.load(this.getCurrentLoadUrl());
            },
            loadData(url = undefined) {
                this.load(url);
            },
            getDisplay: function (value, selector) {
                if (selector) {
                    let res = selector.find((el) => {
                        if (el.id === value)
                            return true
                    });
                    if (res) {
                        return res.title;
                    } else
                        return null;
                } else
                    return null;
            },
            getDisplaySelector: function (value, selector) {
                if (selector) {
                    let res = selector.find((el) => {
                        if (el.value === value)
                            return true
                    });
                    if (res) {
                        return res.text;
                    } else
                        return null;
                } else
                    return null;
            },
            load: function (url = undefined) {
                this.setLoading();
                if (url === undefined)
                    url = this.URL_LIST

                loadData(
                    url,
                    (data) => {
                        this.mapPagination(data);
                        this.mapResults(data, 'items');
                        this.setCurrentLoadUrl(url);
                        this.setNotLoading();
                    }
                )
            },
        },
        created() {
            // this.$store.commit('mainpage/changeTitle','Список'  );
            this.load();
            let url = urls.getPayUrlWithParameters('admin|pay|models|field-list', 'list') + '?type=5'
            this.loadSelector("field", url)
            // url = urls.getPayUrlWithParameters('admin|pay|selectors|selector_value', 'get')
            // this.loadSelector("parentSelectorValue", url)
        }
    }
</script>

<style scoped>
</style>
