export default {
    data() {
        return {
            errors: {
                fields: {},
                non: []
            },

        }
    },
    methods: {
        mapErrors: function (data) {
            if ('non_field_errors' in data)
                this.addNonFieldError(data['non_field_errors']);
            this.errors.fields = data;
        },
        hasError: function (field) {
            for (let val in this.errors.fields){
                if(Object.keys( this.errors.fields[val]).length > 0)
                    if(Object.keys( this.errors.fields[val])[0] === field)
                        return true;
            }
            return false;
        },
        getError: function (field) {
            for (let val in this.errors.fields){
                if(Object.keys( this.errors.fields[val]).length > 0)
                    if(Object.keys( this.errors.fields[val])[0] === field){
                        return this.errors.fields[val][field];
                    }

            }
            return null;
        },
        deleteError: function (field, id = undefined) {
            delete this.errors.fields[field];
            if (id !== undefined) {
                this.$delete(this.errors.fields[id], field);
                if (Object.keys(this.errors.fields[id]).length === 0) {
                    this.$delete(this.errors.fields, id);
                }
            } else {
                delete this.errors.fields[field];
            }
        },
        deleteNonFieldError: function (error) {
            let errors = this.errors.non;
            errors.splice(errors.indexOf(error), 1);
            this.$set(this.errors, 'non', errors);
        },
        addNonFieldError: function (error) {
            let errors = this.errors.non;
            if (errors.indexOf(error))
                errors.push(error);
            this.$set(this.errors, 'non', errors);
            // this.show_non_field_errors = true;
        },
    }
}
