<template>
    <v-list-item
            :class="{ 'v-leftmenu-active': isCurrent(item)}"
            @click="changeRouter(item)"
            class="px-2"
            link
            v-if="item.router"
    >
        <v-list-item-avatar
                size="34"
                v-if="item.icon"
        >
            <v-icon v-text="item.icon"></v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title class="text-left subtitle-2">
                {{ item.title }}
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
        name: "LeftMenuAction",
        props: ['item', 'currentInstance'],
        data() {
            return {}
        },
        methods: {
            changeParam: function (item) {
                if (typeof (item) === "object") {
                    return {name: item.name, params: item.params, query: item.query};
                } else {
                    return {name: item};
                }
            },
            changeRouter: function (item) {
                if (!this.isCurrent(item)) {
                    this.$store.commit('leftmenu/selectInstance', item.instance);
                    this.$router.push(this.changeParam(item.router));
                }
            },
            isCurrent: function (item) {
                if (this.currentInstance !== undefined && item.name !== undefined)
                    return this.currentInstance === item.name;
                return false;
            }
        }
    }
</script>

<style scoped>

</style>
