// import Tools from '@/modules/core/pages/Services'

export default ([
    // {
    //     path: '/tools',
    //     name: 'ToolsPay',
    //     component: Tools,
    //     meta: {
    //         breadcrumb: 'Сервисы',
    //     }
    // },

])


