

export default {
    data() {
        return {
        }
    },
    watch: {},
    methods: {

    },
    created() {
        if(this.search.on_change)
            this.$watch('search.value', () => {
                this.searchData()
            })
    }

}
