import helper from "./helper";
import urls from "@/urls/pay";

let getPayUrlWithParameters = helper.getPayUrlWithParameters


export default {
    CHOICES: {
        PAY_ATTRIBUTE: () => getPayUrlWithParameters(
            'admin|pay|choices|form_pay_attribute_choices',
            'get'
        ),
        TAX_ID: () => getPayUrlWithParameters(
            'admin|pay|choices|form_tax_id_choices',
            'get'
        ),
        CHEKONLINE_ERROR_CODE: () => getPayUrlWithParameters(
            'admin|pay|choices|chekonline_error_code_choices',
            'get'
        ),
    }

}
