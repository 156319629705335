<template>
    <div class="py-3" v-if="value.field">
        <v-form ref="form" v-model="value.field.validVal">
            <v-text-field
                    :hint="value.field.helpText"
                    :placeholder="value.field.placeholder"
                    :readonly="value.field.readOnly ||readTr "
                    :rules="nameRules"
                    outlined
                    persistent-hint
                    type="number"
                    v-if="!value.field.hidden && value.field.type === '0'"
                    v-model.number="value.field.defaultValue"
            >
                <template slot="label">{{ value.field.name }}<span class="red--text"
                                                                   v-if="value.field.required">*</span>:
                </template>
            </v-text-field>

            <v-row class="px-3 py-3" v-if="!value.field.hidden && value.field.type === '1'">
                <v-text-field
                        :hint="value.field.helpText"
                        :placeholder="value.field.placeholder"
                        :readonly="value.field.readOnly||readTr"
                        :rules="nameRules"
                        outlined
                        persistent-hint
                        v-if="value.field.metaType !== '3'"
                        v-model="value.field.defaultValue"
                >
                    <template slot="label">{{ value.field.name }}<span class="red--text"
                                                                       v-if="value.field.required">*</span>:
                    </template>
                </v-text-field>

                <v-row class="py-0" v-else>
                    <v-col cols="6" md="10" sm="6" xs="6">
                        <v-text-field
                                :hint="value.field.helpText"
                                :placeholder="value.field.placeholder"
                                :readonly="value.field.readOnly||readTr"
                                :rules="nameRules"
                                outlined
                                persistent-hint
                                v-model="value.field.defaultValue"
                        >
                            <template slot="label">{{ value.field.name }}<span class="red--text"
                                                                               v-if="value.field.required">*</span>:
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" md="2" sm="6" xs="6">
                        <v-checkbox @change="copyName()" label="Совпадает?" v-model="metaTypeName">
                        </v-checkbox>
                    </v-col>
                </v-row>


            </v-row>


            <v-textarea
                    :hint="value.field.helpText"
                    :placeholder="value.field.placeholder"
                    :readonly="value.field.readOnly || readTr"
                    :rules="nameRules"
                    auto-grow
                    outlined
                    persistent-hint
                    rows="1"
                    v-if="!value.field.hidden && value.field.type === '2'"
                    v-model="value.field.defaultValue"
            >
                <template slot="label">{{ value.field.name }}<span class="red--text"
                                                                   v-if="value.field.required">*</span>:
                </template>
            </v-textarea>

            <v-text-field
                    :hint="value.field.helpText"
                    :placeholder="value.field.placeholder"
                    :readonly="value.field.readOnly || readTr"
                    :rules="nameRules"
                    outlined
                    persistent-hint
                    type="date"
                    v-if="!value.field.hidden && value.field.type === '3'"
                    v-model="value.field.defaultValue"
            >
                <template slot="label">{{ value.field.name }}<span class="red--text"
                                                                   v-if="value.field.required">*</span>:
                </template>
            </v-text-field>
            <v-checkbox
                    :hint="value.field.helpText"
                    :placeholder="value.field.placeholder"
                    :readonly="value.field.readOnly || readTr"
                    :rules="nameRules"
                    outlined
                    persistent-hint
                    v-if="!value.field.hidden && value.field.type === '4'"
                    v-model="value.field.defaultValue"
            >
                <template slot="label">
                    <div>
                    <span v-if="checkboxT.length > 0">
                        {{ checkboxT[0] }}
                    </span>
                        <v-tooltip bottom v-if="value.field.document">
                            <template v-slot:activator="{ on }">
                                <a
                                        :href=value.field.document.file
                                        @click.stop
                                        target="_blank"
                                        v-on="on"
                                >
                              <span v-if="checkboxT.length > 1">
                                <span v-if="checkboxT[1].length > 0">{{ checkboxT[1] }}</span>
                                <span v-else>{{ value.field.document.name }}</span>
                              </span>
                                </a>
                            </template>
                            Открыть документ
                        </v-tooltip>
                        <span v-if="checkboxT.length > 2">
                              {{ checkboxT[2] }}
                              </span>
                        <span class="red--text" v-if="value.field.required">*</span>:
                    </div>
                </template>
            </v-checkbox>

            <v-autocomplete
                    :hint="value.field.helpText"
                    :items="updateSelectorsList"
                    :placeholder="value.field.placeholder"
                    :readonly="value.field.readonly || readTr"
                    :rules="nameRules"
                    item-text="title"
                    item-value="uid"
                    outlined
                    persistent-hint
                    v-if="!value.field.hidden && value.field.type === '5'"
                    v-model="value.field.defaultValue"
            >
                <template slot="label">{{ value.field.name }}<span class="red--text"
                                                                   v-if="value.field.required">*</span>:
                </template>
                <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                        <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                            {{ data.item.title }}
                        </div>
                    </template>
                </template>
            </v-autocomplete>
            <!--      {{selectorValue}}-->
            <v-alert outlined type="error" v-if="hasError(String(value.field.uid))">{{ getError(String(value.field.uid))
                }}
            </v-alert>
        </v-form>
    </div>
</template>

<script>
    import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
    import FormErrorsMixin from '@/mixins/FormErrorsMixin'

    export default {
        name: "PublicField",
        props: {
            selectorValue: {
                type: Array,
                default: () => [null]
            },
            field: Object,
            readTr: {
                type: Boolean,
                default: false
            },
            savetr: {
                type: Boolean,
                default: false
            },
            errors_arr: Object,
            newValCheckBox: {
              type: Boolean,
              default: false
            },
        },
        components: {
            DebugJsonComponent
        },
        mixins: [FormErrorsMixin],
        computed: {
            updateSelectorsList: function () {
                if (this.value.field.type === '5') {
                    if (this.savetr) {
                        return this.value.field.selectorValue
                    } else {
                        let returnArray = []
                        for (let variable of this.selectorValue) {
                            returnArray = returnArray.concat(this.value.field.selectorValue.filter(function (el) {
                                return el.parentSelectorValue === variable;
                            }))
                        }
                        return returnArray
                    }
                } else
                    return [];
            },
            nameRules: function () {
                let rules = []
                if (this.value.field.required){
                  if (this.value.field.helpText)
                    rules.push(v => !!v || this.value.field.helpText)
                  else{
                    rules.push(v => !!v || "Заполните поле")
                  }
                }

                if (this.value.field.regex)
                    if (this.value.field.defaultValue !== null && this.value.field.defaultValue !== "" )
                        rules.push(v => {
                            const pattern = new RegExp(this.value.field.regex)
                            if (this.value.field.helpText)
                                return pattern.test(v) || this.value.field.helpText
                            else
                                return pattern.test(v) || "Заполните поле"
                        })
                if (this.value.field.metaType === '4')
                    if (this.value.field.defaultValue !== null && this.value.field.defaultValue !== "")
                        rules.push(v => {
                            if (String(this.value.field.defaultValue).length !== 20) {
                                return this.value.field.helpText
                            } else {
                                let sum = 0
                                let data = String(this.value.field.defaultValue)
                                for (let char in data) {
                                    if (char % 2)
                                        sum = sum + Number(data[char]) 
                                    else {
                                        sum = sum + Number(data[char]) * 3
                                    }
                                }
                                if (sum ===0)
                                    if (this.value.field.helpText)
                                        return this.value.field.helpText
                                    else
                                        return "Заполните поле"
                                if ((sum - 10 * (Math.floor(sum / 10))) === 0)
                                    return true
                                else
                                    if (this.value.field.helpText)
                                        return this.value.field.helpText
                                    else
                                        return "Заполните поле"
                            }

                        })

                return rules
            }

        },
        watch: {
            value: {
                handler(newTxData, oldTxData) {
                    this.$emit('update:obj', newTxData)
                },

                deep: true
            },
            errors_arr: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    if (newValue.fields) {
                        this.errors = newValue
                    }
                }
            },
            newValCheckBox: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                  this.metaTypeName = newValue
                  this.$emit('updateName', this.metaTypeName)
                }
            }
        },
        methods: {
            copyName() {
                this.$emit('updateName', this.metaTypeName)
            },
            checkboxText(value) {
                return value.split("|");
            }
        },
        data() {
            return {
                value: {},
                metaTypeName: false,
                checkboxT: [],
            }
        },
        created() {
            this.value = this.field
            if (this.value.field.type === '4') {
                this.checkboxT = this.checkboxText(this.value.field.name)
            }
            try {
              if (this.value.field.type === '4') {
                this.value.field.defaultValue = Boolean(this.field.defaultValue)
              }
            }
            catch (e) {
              this.value.field.defaultValue = false
            }

            if (this.value.field.value) {
                this.value.field.defaultValue = this.value.field.value
            }
        }
    }
</script>

<style scoped>

</style>
