import Vue from 'vue'
import Router from 'vue-router'
import PageNotFound from "@/modules/core/pages/PageNotFound";
import coreRouter from "@/modules/core/routers";
import payRouter from "@/modules/pay/routers/routers";
import {authRequiredHook, instanceRequiredHook} from "@/router/hooks";

Vue.use(Router)

const baseRoutes = [
    {
        path: "*",
        component: PageNotFound
    },
];
var allRoutes = [];

allRoutes = allRoutes.concat(
    baseRoutes,
    coreRouter,
    payRouter,
    // educationRouter,
);

const router = new Router(
    {
        base: '',
        mode: 'history',
        routes: allRoutes,
    }
);
router.beforeEach(authRequiredHook);
router.beforeEach(instanceRequiredHook);
// router.beforeEach(toAuthRedirectHook);

export default router;
