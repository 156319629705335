<template>
    <v-container>
        <v-card>
            <v-card-title class="title style-color-main-gray">
                <span>Список выданных прав</span>
                <v-spacer></v-spacer>
                <v-btn @click="opened_form = true" color="secondary" text>
                    <v-icon>add</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                @click="getItems()"
                                class="mr-2"
                                medium
                                v-on="on"
                        >
                            update
                        </v-icon>
                    </template>
                    <span>Обновить данные</span>
                </v-tooltip>
            </v-card-title>

            <v-data-table
                    :footer-props="{'items-per-page-options':[100, 250, 500], 'items-per-page-text': 'Число элементов'}"
                    :headers="headers"
                    :items="items"
                    :items-per-page.sync="pagination.size"
                    :page.sync="pagination.page"
                    :server-items-length="pagination.count"
                    no-data-text="Данные отсутствуют"
                    no-results-text="Ничего не было найдено"
            >
                <template v-slot:item.role="{ item }">
                    {{ getDisplay(item.role, selectors.role) }}
                </template>
                <template v-slot:item.btn="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="updateItem(item)"
                                    class="mr-2"
                                    medium
                                    v-on="on"
                            >
                                edit
                            </v-icon>
                        </template>
                        <span>Редактировать</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    @click="openConfirmDelete(item)"
                                    medium
                                    v-on="on"
                            >
                                delete
                            </v-icon>
                        </template>
                        <span>Удалить</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <v-dialog @input="closedForm" persistent v-model="opened_form">
                <Form @addItem="addItem"
                      @ended="closedForm"
                      @refreshItem="refreshItem"
                      v-bind="selected"
                      v-bind:info="$route.params.id"
                      v-if="opened_form"
                >
                </Form>
            </v-dialog>

            <v-dialog persistent v-model="opened_confirm" width="500">
                <v-card v-if="deletable">
                    <v-card-title>Подтвердите удаление</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        Вы уверены что хотите удалить?<br>
                    </v-card-text>
                    <v-card-actions>
                        <v-flex>
                            <v-btn
                                    @click="deleteItem(deletable.id)"
                                    color="warning"
                                    text
                            >
                                Удалить
                            </v-btn>
                            <v-btn
                                    @click="closeConfirmDelete"
                                    text
                            >Отмена
                            </v-btn>
                        </v-flex>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
    import {generateListUidToName} from "@/helper/uidToName";
    import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
    import UserDataMixin from '@/mixins/UserDataMixin'
    import urls from '@/urls/helper.js'
    import Form from "./Form"
    import {loadData, makeGetRequest} from "@/helper/requests";

    export default {
        name: "List",
        mixins: [
            PaginatedDataMapperMixin,
            UserDataMixin
        ],
        components: {
            Form
        },
        props: {},
        data() {
            return {
                selectors: {
                    role: [],
                },

                items: [],
                URL_LIST: urls.getPayUrlWithParameters('admin|permissions|models|role_user-list', 'list'),
                URL_DELETE: urls.getPayUrlWithParameters('admin|permissions|models|role_user-detail', 'destroy'),
                headers: [
                    {text: 'Пользователь', sortable: false, align: 'left', value: 'fio',},
                    {text: 'Роль', sortable: false, align: 'left', value: 'role',},
                    {text: 'Действия', sortable: false, align: 'right', value: 'btn'},
                ],
            }
        },
        methods: {
            loadSelector(data, url) {
                let sys = true
                makeGetRequest(url).then(resp => {
                    if (!resp.ok) {
                        sys = false
                    }
                    return resp.json()
                }).then(json => {
                    this.selectors[data] = json
                    this.selectors[data].push({title: 'Не указано', id: null})
                })
            },
            getItems() {
                this.closedForm()
                this.load();
            },
            addItem() {
                this.closedForm()
                this.load();
            },
            refreshItem() {
                this.closedForm()
                this.load();
            },

            loadData(url = undefined) {
                this.load(url);
            },

            load: function (url = undefined) {
                if (url === undefined)
                    url = this.URL_LIST,
                        loadData(
                            url,
                            (data) => {
                                this.mapPagination(data);
                                this.mapResults(data, 'items');
                                this.setCurrentLoadUrl(url)
                                for (let i in this.items) {
                                    this.$set(this.items[i], "fio", "-")
                                }
                                generateListUidToName(this.items, "user", "fio")
                            }
                        )
            },
        },
        created() {
            this.getItems()
            let url = urls.getPayUrlWithParameters('admin|permissions|choices|role_choices', 'get')
            this.loadSelector("role", url)
        }
    }
</script>

<style scoped>
</style>
