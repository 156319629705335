<template>
    <v-container class="subprimary" fluid>
        <v-row align="start" align-content="start" v-if="mainpage.title">
            <v-col class="pt-5 pb-1" cols="auto">
                <!--        <h1>{{ mainpage.title }}</h1>-->
            </v-col>
        </v-row>
        <!--    <v-divider v-if="mainpage.title"></v-divider>-->
        <v-row align="start" align-content="start">
            <v-col class="pt-1 pb-2" cols="auto">
                <breadcrumbs style="padding-top: 6px"></breadcrumbs>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: "PageHeader",
        computed: mapState({
            mainpage: state => state.mainpage,
        }),
    }
</script>

<style scoped>

</style>
