import {getUserToken} from "@/helper/index";

export function getHttpHeaders() {
    /**
     * Возвращает словарь заголовков запроса для отправки запрос содержащего JSON пакет данных
     * @return {Map} промис запроса
     */
    let headers = {
        'Content-Type': 'application/json',
    }
    if (getUserToken())
        headers['Authorization'] = 'Token ' + getUserToken()
    // console.log(headers)
    return headers
}

export function getHttpHeadersFile() {
    /**
     * Возвращает словарь заголовков запроса для отправки запроса содержащего файлы
     * @return {Map} промис запроса
     */
    let headers = {}
    if (getUserToken())
        headers['Authorization'] = 'Token ' + getUserToken()
    return headers
}

export function makeGetRequest(url, addheaders) {
    /**
     * Выполняет запрос типа GET для запроса данных
     *
     * @param {string} url - адресс на который посылается запрос
     * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
     *
     * @return {Promise} промис запроса
     *
     */
    let headers = getHttpHeaders();
    return fetch(url, {
        headers: headers,
        method: 'GET',
    });
}

export function makePostRequest(url, data, method, addheadres) {
    /**
     * Выполняет запрос типа POST с отправкой данных упакованных в JSON пакет
     *
     * @param {string} url - адресс на который посылается запрос
     * @param {Object} data - данные упакованные в объект отправляемые на адресс
     * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
     *
     * @return {Promise} промис запроса
     *
     */
    let headers = getHttpHeaders()
    if (!method)
        method = 'POST'
    return fetch(url, {
        headers: headers,
        method: method,
        body: JSON.stringify(data),
    });
}

export function makePostRequestFile(url, data, method, addheadres) {
    /**
     * Выполняет запрос настраиваемого типа с отправкой данных типа FormData
     *
     * @param {string} url - адресс на который посылается запрос
     * @param {Object} data - данные упакованные в объект отправляемые на адресс
     * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
     *
     * @return {Promise} промис запроса
     *
     */
    let headers = getHttpHeadersFile()
    if (!method)
        method = 'POST'
    return fetch(url, {
        headers: headers,
        method: method,
        body: data,
    });
}

export function makeUpdateRequest(url, data, addheaders) {
    /**
     * Выполняет запрос типа PUT с отправкой данных упакованных в JSON пакет
     *
     * @param {string} url - адресс на который посылается запрос
     * @param {Object} data - данные упакованные в объект отправляемые на адресс
     * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
     *
     * @return {Promise} промис запроса
     *
     */
    let headers = getHttpHeaders()
    return fetch(url, {
        headers: headers,
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export function makeParticalUpdateRequest(url, data, addheaders) {
    /**
     * Выполняет запрос типа PATCH для частичного изменения какого то объекта
     *
     * @param {string} url - адресс на который посылается запрос
     * @param {Object} data - данные упакованные в объект отправляемые на адресс
     * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
     *
     * @return {Promise} промис запроса
     *
     */
    return makePostRequest(url, data, "PATCH")
}

export function makeDeleteRequest(url) {
    /**
     * Выполняет запрос типа DELETE с возможностью отправки данных
     *
     * @param {string} url - адресс на который посылается запрос
     * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
     *
     * @return {Promise} промис запроса
     *
     */
    let headers = getHttpHeaders()
    return fetch(url, {
        headers: headers,
        method: 'DELETE',
    });
}

export function finalizeRequest(request, finalizer = () => {
}, catcher = () => {
}) {
    /**
     * Функция завершающая запрос и прокидывающая колбеки внутрь промисов
     *
     * @param {Promise} request - промис произовольного запроса, вокруг которого нужно сделать обертку
     * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
     * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
     *
     */
    request
        .then(resp => {
            if (!resp.ok) {
                return resp.json()
                    .then((json) => catcher(json))
                    .catch(() => catcher(undefined));
            } else {
                return resp.json()
                    .then(json => finalizer(json))
                    .catch(() => finalizer(undefined));
            }
        }).catch(error => catcher(error))

}

export function sendGetRequest(url, finalizer = () => {
}, catcher = () => {
}) {
    /**
     * Функции выполняющей гет запрос при помощи промисов
     *
     * @param {string} url - урл с которого загружаются данные
     * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
     * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
     *
     * */
    finalizeRequest(makeGetRequest(url), finalizer, catcher);
}

export function sendPatchRequest(url, data, finalizer = () => {
}, catcher = () => {
}) {
    /**
     * Функции выполняющей гет запрос при помощи промисов
     *
     * @param {string} url - урл с которого загружаются данные
     * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
     * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
     *
     * */
    finalizeRequest(makeParticalUpdateRequest(url, data), finalizer, catcher);
}


export function sendPostRequest(url, data, finalizer = () => {
}, catcher = () => {
}) {
    /**
     * Функции выполняющей гет запрос при помощи промисов
     *
     * @param {string} url - урл с которого загружаются данные
     * @param {Object} data - данные отправляемые
     * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
     * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
     *
     * */
    finalizeRequest(makePostRequest(url, data), finalizer, catcher);
}

export function sendPutRequest(url, data, finalizer = () => {
}, catcher = () => {
}) {
    /**
     * Функции выполняющей гет запрос при помощи промисов
     *
     * @param {string} url - урл с которого загружаются данные
     * @param {Object} data - данные отправляемые
     * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
     * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
     *
     * */
    finalizeRequest(makeUpdateRequest(url, data), finalizer, catcher);
}

export function sendDeleteRequest(url, finalizer = () => {
}, catcher = () => {
}) {
    /**
     * Функции выполняющей гет запрос при помощи промисов
     *
     * @param {string} url - урл с которого загружаются данные
     * @param {Object} data - данные отправляемые
     * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
     * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
     *
     * */
    finalizeRequest(makeDeleteRequest(url), finalizer, catcher);
}

export function loadData(url, saver = () => {
}, catcher = () => {
}) {
    /**
     * Ренейм функции выполняющей гет запрос
     *
     * @param {string} url - урл с которого загружаются данные
     * @param {function} saver - функция которая выполняется после загрузки данных, и сохраняет их
     * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
     *
     * */
    sendGetRequest(url, saver, catcher);
}

export function setUrlParameters(url, parameters) {
    /**
     * замена параметров урла
     *
     * @param {string} url - адресс в котором будут проводиться замены параметров
     * @param {Map} parameters - словарь заменяемых параметров, где ключ это что заменяем, а значение на что заменяем
     *
     * @return {string} итоговый урл
     */
    for (let key in parameters) {
        url = url.replace('<' + key + '>', parameters[key]);
    }
    return url
}

export function addGetParameters(url, parameters) {
    /**
     * Добавление к адресу гет параметры
     *
     * @param {string} url - адресс к которому добавляются гет параметры
     * @param {Map} parameters - словарь добавляемых параметров, где ключ название параметра а значаение это добавляемое значение
     * @type {Array}
     */
    let urlObject = new URL(url);
    for (let el of Object.keys(parameters)) {
        if (parameters[el] === undefined || parameters[el] == null)
            urlObject.searchParams.delete(el)
        else
            urlObject.searchParams.set(el, parameters[el])
    }
    return urlObject.toString();
}
